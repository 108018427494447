@use '~scss/settings' as *;

.create-client-modal {
  .modal__container__wrapper {
    width: 720px;
    @include flex(column, flex-start);
    &__subtitle {
      width: 100%;
    }
    &__body {
      flex: 1;
    }
    @include tablet {
      width: 24rem;
    }
    @include mobile {
      width: 22rem;
    }
  }
}
