@use '~scss/settings' as *;

.dashboard {
  @include size(100%);
  @include flex(column, flex-start, flex-start);
  overflow: scroll;
  padding-bottom: 1.75rem;
  &__header {
    padding-inline: 1.75rem;
  }

  &__main {
    @include size(100%);
    @include flex(column, flex-start, flex-start);

    padding-inline: 1.75rem;

    gap: 3rem;
    @include tablet() {
      gap: 2rem;
    }
    @include mobile() {
      gap: 0.5rem;
    }

    &__header {
      @include size(100%, 120px);

      background-color: white;
    }

    &__body {
      &__loader {
        @include flex(column, flex-start, center);
        @include size(100%);
        gap: 0.5rem;
        &__max-content {
          flex: 1;
          width: 100%;
          position: relative;
        }
        &__min-content {
          @include flex(row);
          gap: 0.5rem;
          flex: 1;
          width: 100%;
          @include tablet {
            flex-direction: column;
          }
        }
      }
    }
  }
}
