@use '~scss/settings' as *;

.is-register {
  @include flex(column, space-between, center);

  background-color: $ghost-white;
  height: 100%;

  &__header {
    @include flex(row, space-between, center);

    width: 100%;
    padding: 20px 40px;
  }

  &__info {
    @include font(35px, 53px, 400);
    @include flex(column, center, center);

    width: 100%;
    gap: 50px;
    color: $blue-depths;
    margin: 0px;
  }

  &__content {
    @include flex(column, flex-start, center);
    @include size(450px, 100%);

    padding: 30px 0px 70px;

    .is-register-form {
      @include flex(column, space-between, center);
      @include size(100%, 100%);

      &__inputs {
        @include flex(column, center, center);
        @include size(100%, 100%);

        gap: 40px;
      }

      &__buttons {
        @include flex(row, space-around, flex-end);

        width: 100%;

        button {
          width: fit-content;
          min-width: 150px;
          padding: 0 1.5rem;
        }
      }
    }
  }

  &__footer {
    @include flex(row, space-between, center);

    width: 100%;
    padding: 14px 20px;

    .is-copyright {
      @include font(14px, 22px, 400);

      margin: 0px;
      color: $copyright;
    }
  }

  &__submit {
    border: 0;
    box-shadow: none;
    border-radius: 0;
  }
}

@include tablet() {
  .is-register {
    &__content {
      width: 400px;
    }

    &__footer {
      @include flex(row, center, center);

      padding-left: 0px;
    }
  }
}

@include mobile() {
  .is-register {
    &__info {
      @include font(30px, 45px, 400);
    }

    &__content {
      width: 300px;
    }
  }
}
