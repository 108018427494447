@use '~scss/settings' as *;

.invalid-invitation {
  @include size(100%);
  background-color: $ghost-white;

  &__text-row {
    @include font(20px, 20px, 400);
  }

  &__button {
    width: fit-content;
    padding-inline: 16px;
    margin-top: 4rem;
  }

  .auth-wrapper {
    &__content {
      margin-top: 0;
      p {
        text-align: center;
      }
      &__description {
        height: fit-content;
        margin-bottom: 2rem;
        p {
          margin: 0;
        }
      }
    }
  }
}
