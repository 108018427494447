@use '~scss/settings' as *;

.client-preview {
  @include flex(column, center, flex-start);
  font-family: 'Arimo';
  padding: 20px;
  padding-inline: 30px;
  background-color: $color-white;
  color: $blue-depths;
  gap: 4px;
  position: relative;

  &:hover {
    background-color: $ghost-white;
  }

  &--clickable {
    cursor: pointer;
  }

  &__link {
    @include size(fit-content);

    padding: 2px 12px;
    display: none;

    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);

    &__icon {
      @include size(30px, 24px);
      fill: $color-black-29;
    }
  }
  &:hover {
    .client-preview__link {
      @include flex(row, center, center);
    }
  }

  &__info {
    @include flex(row, space-between, center);
    width: 100%;

    * {
      margin: 0px;
    }

    &__basic {
      @include flex(row, center, center);

      &__border {
        height: 14px;
        border-left: 1px solid $blue-depths;
        display: inline-block;
        margin-right: 10px;
      }

      &__name {
        @include font(15px, 23px, 700);
        width: 124px;
        margin-right: 6px;

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &--show {
          &:hover {
            text-overflow: unset;
            overflow: unset;
            & ~ .client-preview__info__basic {
              &__projects {
                display: none;
              }
              &__border {
                display: none;
              }
            }
          }
        }
      }

      &__projects {
        @include flex(row, center, center);
        @include font(12px, 10px, 700);
        column-gap: 6px;
      }
    }
  }

  &__subtext {
    @include font(12px, 18px, 400);
    @include flex(row, flex-start, center);
    gap: 4px;
    margin: 0px;
    svg {
      @include size(14px);
    }
  }

  &__buttons {
    @include flex(row);
    gap: 0;
    &__button {
      @include size(70px, 30px);
      .is-button__label {
        @include font(16px, 10px, 400);
      }
      &:hover {
        background-color: $glitter;
        .is-button__label {
          color: $blue-depths;
        }
      }
    }
    &__delete-button {
      svg {
        fill: $fusion-red;
      }
      &:hover {
        background-color: $glitter;
      }
    }
  }

  &--selected {
    background-color: $ghost-white;
    .client-preview {
      &__info {
        &__basic {
          &__projects {
            &:hover {
              border-color: $color-white;
            }
            &--unclickable {
              &:hover {
                border-color: transparent;
              }
            }
          }
        }
      }
      &__buttons__delete-button {
        svg {
          fill: $fusion-red;
        }
      }
    }
  }
}
