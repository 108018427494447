@use '~scss/settings' as *;

.table-payments {
  border-collapse: collapse;
  width: 100%;

  &--not-selected {
    td {
      opacity: 0.4;
    }
    background-color: $color-gray-f6f6f6;
  }

  &--clickable {
    cursor: pointer;
    &:hover.table-payments--not-selected {
      opacity: 0.7;
    }
  }

  &--highlight:not(.table-payments--checked) {
    animation: highlight 2s;
  }
  &--highlight.table-payments--checked {
    animation: highlight-selected 2s;
  }
  &--highlight.table-payments__row--error {
    animation: highlight-error 2s;
  }

  &--mode-processing {
    .is-checkbox--checked {
      .is-checkbox__button {
        outline-color: $islamic-green;
        background-color: $islamic-green;
      }
    }
  }

  &--mode-verifiying,
  &--mode-processing,
  &--mode-error,
  &--mode-editing {
    td:nth-child(1) {
      opacity: 1;
    }
  }

  &--mode-error {
    .is-checkbox--checked {
      .is-checkbox__button {
        outline-color: $fusion-red;
        background-color: $fusion-red;
      }
    }
  }

  &__icon {
    &--checked {
      @include size(24px);
    }
  }
  &__buttons {
    @include flex(row, flex-end, center);
    height: 26px;
  }
  &__link {
    text-wrap: nowrap;
  }

  tr {
    border: 10px solid $color-white;
    th,
    td {
      padding: 14px;
    }

    td:last-child {
      width: 40px;
      vertical-align: middle;
    }
  }

  th {
    @include font(16px, 23px, 700);

    font-family: Arimo;
    text-align: start;
    color: $color-gray-94;
    text-wrap: nowrap;
  }

  tbody {
    tr {
      td {
        vertical-align: baseline;
      }

      td {
        @include font(16px, 23px, 700);
      }
      td {
        @include font(16px, 30px, 700);
      }
    }
  }

  &__icon {
    @include size(24px);
    &--error {
      fill: $fusion-red;
    }
  }
  &__row {
    &--error {
      background-color: $payment-error-background;
    }
    &--processed {
      background-color: $honeydew;
    }
  }

  &--mode-error,
  &--mode-none {
    .table-payments__row--error {
      color: $color-black-29;
    }
  }

  &__tool-tip {
    max-width: 300px;
    &-icon {
      font-size: 0;
      svg {
        display: block;
      }
    }
  }
}
