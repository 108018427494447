@use '~scss/settings' as *;

.progress-card {
  @include flex(row);
  width: 12rem;
  box-shadow: $shadow-md;
  gap: 1.25rem;
  &__left {
    @include flex(row, flex-end, flex-end);
    &__scale {
      background-color: $duke-blue;
      width: 0.5rem;
    }
  }

  &__right {
    padding: 0.25rem 0;
    &__processed-items {
      margin: 0 0 0.25rem 0;
    }
    &__status {
      margin-bottom: 1rem;
      color: $palladium;
    }
  }
}
