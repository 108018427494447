@use '~scss/settings' as *;

.preview-card {
  height: 100%;
  min-height: 12rem;
  background-color: $color-white;
  word-break: break-word;
  box-shadow: $shadow-md;

  @include above-tablet {
    min-width: 24rem;
  }

  &__heading {
    border-bottom: 2px solid $color-black-border;
    &__upper {
      @include flex(row, flex-start, center);
      right: 0;
      min-height: 65px;
      width: 100%;
      padding: 0 1.75rem;

      &__name {
        @include flex(row, flex-start, center);
        gap: 0.25rem;
        &__title {
          @include font(24px, 24px, 400);
          text-wrap: nowrap;
        }
        &__title-icon {
          @include flex(row, center, center);
        }
      }
      .is-button {
        margin-left: auto;
      }
    }
  }

  &__content {
    width: 100%;
    padding: 0 1rem;
    &__wrap {
      @include size(100%);
    }
  }
}
