@use '../../scss/settings' as *;

.is-input {
  width: 100%;
  user-select: none;

  &--squared {
    .is-input {
      &__content {
        border-top: 3px solid $palladium;
        border-right: 3px solid $palladium;
        border-left: 3px solid $palladium;
      }
      &__input {
        background-color: $background-input-gray;
        text-indent: 8px;
      }
    }
  }

  &--invisible {
    .is-input {
      &__content {
        border: none;
      }
      &__input {
        border: none;
        text-indent: 8px;
      }
      &__footer {
        display: none;
      }
      &__header {
        display: none;
      }
    }
  }
  &--invisible.is-input--error {
    .is-input__content {
      border-bottom: 0;
      background-color: $color-white;
    }
  }

  &--warning {
    .is-input {
      &__label {
        color: $warning-yellow;
      }
      &__input {
        background-color: $warning-yellow-bright;
      }
      &__content {
        border-color: $warning-yellow;
      }
    }
  }
  &--disabled {
    opacity: 0.4;
    .is-input__input {
      cursor: not-allowed;
    }
  }

  &--disabled,
  &--disabled.is-input--error {
    .is-input {
      cursor: not-allowed;
      opacity: 0.4;
      &__footer {
        p {
          color: transparent;
        }
      }
    }
  }

  &--squared.is-input--disabled {
    opacity: 0.4;
    .is-input {
      &__input {
        cursor: not-allowed;
      }
    }
  }

  &__label {
    @include mobile() {
      font-size: 18px;
    }
    color: $black-oak;
    margin-right: 10px;
    font-size: 20px;
    margin-bottom: 3px;
  }

  &__input {
    @include size(100%, 40px);
    @include mobile() {
      font-size: 18px;
    }
    font-family: Arimo;
    font-size: 20px;
    border: none;
    background-color: transparent;
    padding: 0px;
    color: $black-oak;
  }

  &__input:focus-visible {
    outline: none;
  }

  &__input::placeholder {
    font-family: Arimo;
    color: $palladium;
  }

  &__input:focus {
    color: black;
  }

  &__header {
    @include flex(row, space-between, center);
    height: 30px;

    &__left {
      @include flex(row, center, center);
      svg {
        fill: $black-oak;
      }
    }

    &__right {
      @include flex(row, center, center);

      a {
        @include mobile() {
          font-size: 12px;
        }
        font-size: 15px;
        margin: 0;
        text-decoration: none;
        color: $black-oak;
      }
      a:hover {
        color: $duke-blue;
      }
    }
  }

  &__content {
    @include flex(row, center, center);
    border: none;
    border-bottom: 3px solid $palladium;
  }

  &__footer {
    min-height: 22px;
    p {
      user-select: none;
      color: $fusion-red;
      margin: 0px;
    }
  }

  &--error {
    input::placeholder {
      color: transparent;
    }
    .is-input__content {
      background-color: rgba(255, 0, 0, 0.041);
      border-bottom: 3px solid $fusion-red;
    }
  }
}
