@use '~scss/settings' as *;

.preview-card__content__wrap {
  min-width: auto;
}

.project-table {
  border-collapse: collapse;

  width: 100%;
  overflow-x: auto;
  display: block;
  white-space: nowrap;

  tr {
    border: 10px solid $color-white;
    th,
    td {
      padding: 16px 20px;
    }
  }

  th {
    @include font(15px, 23px, 700);

    font-family: Arimo;
    text-align: start;
    color: $color-gray-94;
  }

  tbody {
    tr {
      cursor: pointer;
      td {
        padding-block: 17px;
      }
      td:nth-child(-n + 4) {
        vertical-align: baseline;
      }
      td:nth-child(-n + 3) {
        @include font(18px, 23px, 700);
      }
      td:nth-child(1) {
        width: 400px;
      }
      td:nth-child(2) {
        width: 400px;
      }
      td:nth-child(3) {
        width: 300px;
      }
      td:nth-child(4) {
        width: 400px;
      }
    }
  }

  &__row {
    &:hover {
      background-color: $honeydew;
    }
  }

  &__button {
    .is-button__label {
      @include font(16px, 20px, 400);
      color: $black-oak;
    }
    @include size(auto);
    padding: 4px 12px;
    background-color: $color-gray-f6f6f6;

    &:hover {
      .is-button__label {
        color: $black-oak;
      }
    }
  }
}
