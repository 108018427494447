@use '../../scss/settings' as *;

.is-checkbox {
  @include gap(20px, row);
  @include flex(row, space-between, center);
  width: max-content;
  :hover {
    cursor: pointer;
  }

  &__button {
    @include size(15px);
    margin: 3px;
    transition-property: border-radius;
    transition: 0.2s;
    border: 3px solid transparent;
    outline: 3px solid $black-oak;
  }

  &--login {
    .is-checkbox {
      &__label {
        font-size: 16px;
        margin-left: 0.75rem;
      }
      &__button {
        @include size(0.6rem);
        outline: 2px solid $black-oak;
        border-radius: 1px;
      }
    }
  }
  &__label {
    user-select: none;
    font-size: 20px;
  }

  &--checked {
    .is-checkbox__button {
      background-color: $duke-blue;
      border: 3px solid $azure-mist;
      outline: 3px solid $duke-blue;
    }
  }

  &--error {
    .is-checkbox__button {
      outline: 3px solid $grapefruit;
    }
    .is-checkbox__label {
      color: $fusion-red;
    }
  }
}
