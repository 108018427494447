@use '~scss/settings' as *;

.toggle {
  @include size(40px, 34px);
  @include flex(row, center, center);
  background-color: $color-white;
  border-radius: 4px;

  transition: transform 0.15s, background-color 0.15s, stroke 0.15s,
    border 0.15s;
  cursor: pointer;
  border: transparent;

  path {
    stroke: $palladium;
  }

  &:hover:not(.toggle--disabled) {
    border: 1px solid $ghost-white-active;
  }

  &:active:not(.toggle--disabled) {
    transform: translateY(1px);
    background-color: $ghost-white-active;
  }

  &--toggled {
    background-color: $ghost-white;
    path {
      stroke: $black-oak;
    }
  }
  &--disabled {
    cursor: not-allowed;
    background-color: $border-gray;
    path {
      stroke: $color-white;
    }
  }
}
