@use '~scss/settings' as *;

.currency-input-field {
  @include flex(column);
  .is-input {
    .is-input__footer {
      display: none;
    }
  }
  &--error {
    .is-input__content {
      border-bottom-color: $fusion-red;
    }
  }
}
