@use '~scss/settings' as *;

.dropdown {
  position: relative;

  &__trigger {
    position: relative;
  }

  &__content {
    box-shadow: $shadow-md;
    z-index: 4;

    visibility: hidden;
    position: fixed;
    direction: auto;
    width: fit-content;

    > ul {
      list-style: none;
      padding: 0px;
      margin: 0px;

      > li {
        word-wrap: break-word;
        cursor: pointer;
      }
    }

    &--open {
      visibility: visible;
      animation: openDropdown 0.3s ease-in-out;
      transform-origin: top center;
    }

    &--up {
      // bottom: 100%;
      transform-origin: bottom center;
    }
  }
}

.dropdown-header-profile-menu {
  &__list {
    background-color: $color-white;
    box-shadow: 0px 3px 12px $box-shadow-profile-menu;

    &-item {
      @include font(18px, 30px, 400);
      @include flex(row, center, center);

      @include mobile() {
        @include font(15px, 30px, 400);
      }

      color: $black-oak;

      @for $i from 1 through 4 {
        &:nth-child(#{$i}) {
          transform-origin: top center;
          animation: listItemDropdown 300ms ($i * 30ms) ease-in-out;
        }
      }

      .custom-link {
        gap: 10px;
      }

      &:first-child {
        .custom-link {
          svg {
            width: 18px;
            height: 15px;
            fill: $black-oak;
          }
        }
      }

      &:hover {
        background-color: $alice-blue;
        color: $duke-blue;
        width: 100%;

        .custom-link {
          path {
            fill: $duke-blue;
            opacity: 1;
          }
        }
      }

      a {
        @include size(100%);

        color: inherit;
        padding: 14px 20px;
      }
    }
  }
}

@keyframes openDropdown {
  0% {
    transform: scaleY(0);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes listItemDropdown {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  80% {
    transform: scale(1.07);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
