@use '~scss/settings' as *;

.preview-card-with-tabs {
  @include flex(column);
  flex: 1;
  &__tabs {
    @include flex(row);
    position: relative;
    top: 2px;
    align-self: flex-end;
    &__tab {
      border: 2px solid transparent;
      padding: 10px 16px;
      &:hover {
        background-color: $background-input-gray;
        border-bottom-color: $border-gray;
      }
      &--selected {
        border: 2px solid $border-gray;
        border-bottom-color: $color-white;
        &:hover {
          border-bottom-color: $color-white;
        }
      }
    }
  }

  & > .preview-card {
    @include flex(column);
    &__content {
      flex: 1;
    }
  }
}
