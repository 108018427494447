@use '~scss/settings' as *;

.empty-project-state {
  @include size(100%);
  position: relative;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  @media (max-width: 1900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include below-responsive-md {
    grid-template-columns: repeat(1, 1fr);
    padding-inline: 1rem;
  }
  overflow-y: auto;

  &--list-view {
    @include flex(column);
    gap: 0;
  }

  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
    border-radius: 4px;

    line {
      stroke: $color-white;
    }
    &:hover {
      background-color: $blue-depths-darker-hover;
    }
  }
}
