@use '~scss/settings' as *;

.comment-modal {
  &__text-area {
    @include size(100%);
    min-width: 300px;
    max-width: 500px;

    max-height: 300px;
    min-height: 100px;
  }
  &__buttons {
    @include flex(row, space-between);
  }
}
