@use '~scss/settings' as *;

.projects-card {
  @include flex(column);
  @include size(100%);
  flex: 1;
  overflow-x: auto;

  .project-table {
    overflow-x: visible;
  }

  .preview-card {
    &__content {
      flex: 1;
      &__wrap {
        @include flex(column);
      }
    }
  }

  &__footer {
    @include flex(row, space-between, center);
    margin-top: auto;
    min-height: 62px;
    &__pagination-bar {
      margin-left: auto;
    }
  }

  &__loading {
    &__header {
      @include size(100%, 65px);
      margin: 4px 0;
    }
    &__row {
      @include size(100%, 68px);
      margin: 2px 0;
    }
  }

  &__no-data {
    @include size(100%);
    @include flex(column, center, center);
    &__button {
      width: fit-content;
      padding-inline: 16px;
      line {
        stroke: $color-white;
      }
    }
  }
}
