@use '~scss/settings' as *;

.projects {
  @include size(100%);
  @include flex(column, flex-start, center);
  padding: 0 1.75rem 1.75rem 1.75rem;

  &__arrow {
    height: 18px;
    &--up {
      transform: rotateZ(-90deg) translateY(-50%);
      fill: green;
    }
    &--down {
      transform: rotateZ(90deg) translateY(50%);
      fill: red;
    }
  }

  .search-input {
    min-width: 350px;
  }

  &__body {
    @include flex(column, flex-start);
    gap: 16px;
    width: 100%;
    flex: 1;

    .project-table {
      tbody {
        tr {
          td {
            padding-block: 18px;
          }
        }
      }
    }

    &__info {
      .project-info-card:nth-child(-n + 3),
      .projects-info-list__card-loader:nth-child(-n + 3) {
        flex: 1;
      }
      .project-info-card:nth-child(n + 4),
      .projects-info-list__card-loader:nth-child(n + 4) {
        flex: 2;
      }
    }

    &__card {
      @include flex(column, flex-start);
      .preview-card__content {
        flex: 1;
      }
    }

    &__sort {
      margin-left: auto;
    }

    &__empty-state {
      @include flex(column, center, center);
      @include size(100%);
      &__loading {
        @include size(50px);
        border-width: 8px;
      }
      &__paragraph {
        @include font(22px, 22px, 400);
        margin: 0;
      }
      &__animation {
        @include size(350px);
      }
    }
  }

  .projects-card {
    .skeleton-loader {
      height: 64px;
    }
  }
}
