@use '~scss/settings' as *;

.radio-button {
  @include size(fit-content);
  @include font(18px, 18px, 400);
  padding-left: 28px;
  margin-block: 1rem;

  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    @include size(18px);
    position: absolute;
    top: 0;
    left: 0;
    background-color: $ghost-white;
    border: 1px solid $black-oak;
    border-radius: 50%;

    &:after {
      @include size(14px);
      content: '';
      position: absolute;
      display: none;
      top: 1px;
      left: 1px;
      border-radius: 50%;
      background: $duke-blue;
    }
  }

  &:hover &__input ~ &__checkmark {
    background-color: $border-gray;
  }

  &__input:checked ~ &__checkmark {
    background-color: $ghost-white;

    &:after {
      display: block;
    }
  }
}
