@use '~scss/settings' as *;

.link {
  &--default {
    text-decoration: underline;
    color: $duke-blue;
  }
  &--icon {
    transition: background-color 100ms ease-in;
    color: $color-black-29;
  }

  &--button {
    background-color: $color-gray-f2f3;
    transition: background-color 0.25s;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    color: $color-black-29;
    padding: 4px 8px;
    height: 28px;
    padding-inline: 16px;
    border-radius: 2px;
    svg {
      fill: $color-black-29;
    }
    &:hover {
      background-color: $color-gray-e7;
      svg {
        fill: $color-black-29;
      }
    }
  }
}
