@use '~scss/settings' as *;

$nodeborderwidth: 4px;

.is-stepper {
  @include flex(row, center, center);
  height: 50px;
}

.is-stepper-node {
  @include flex(row, center, center);
  @include size(25px, 25px);
  transform: rotate(45deg);

  svg {
    position: absolute;
    transform: rotate(-45deg);
    fill: $ghost-white;
  }
}

.is-stepper-connector {
  height: $nodeborderwidth;
  width: 60px;
}

.is-stepper-node-wrapper:last-child {
  .is-stepper-connector {
    display: none;
  }
}
.is-stepper-node-wrapper {
  @include flex(row, flex-start, center);
  height: 50px;

  &--active {
    .is-stepper-node {
      border: $nodeborderwidth solid $natures-masterpiece;
    }
    .is-stepper-connector {
      background-color: $blue-depths;
    }
  }

  &--default {
    .is-stepper-node {
      border: $nodeborderwidth solid $blue-depths;
      background-color: transparent;
    }
    .is-stepper-connector {
      background-color: $blue-depths;
    }
  }

  &--checked {
    .is-stepper-node {
      border: $nodeborderwidth solid $natures-masterpiece;
      background-color: $natures-masterpiece;
      transition: 0.6s;
      transition-property: background-color;
    }
    .is-stepper-connector {
      background-color: $natures-masterpiece;
    }
  }
}
