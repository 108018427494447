@use '~scss/settings' as *;

.forgot-password-email {
  &__submit {
    border: 0;
    border-radius: 0;
    box-shadow: none;
  }
  &__modal {
    .modal__container__wrapper {
      @include size(490px, 430px);
      padding: 2rem;
      &__body {
        @include flex(column, center, center);
      }
      &__header__title__heading {
        padding-bottom: 1rem;
      }
    }
    &__description {
      margin: 0;
      margin-bottom: 2rem;
      color: $palladium;
    }
    &__icon {
      margin-bottom: 1rem;
    }

    &__button {
      @include size(clamp(300px, 100%, 100%), 50px);
      margin-top: 2rem;
    }
  }
}
