@use '~scss/settings' as *;

.projects-info-list {
  @include flex(row, space-evenly);
  gap: 8px;
  flex-wrap: wrap;
  .project-info-card {
    width: max-content;
    & * {
      text-wrap: nowrap;
    }
  }

  &__card-loader {
    min-width: 180px;
    height: 74px;
  }
}
