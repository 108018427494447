@use '~scss/settings' as *;

.creditor-details {
  @include flex(column);
  height: 100%;
  padding: 4px 8px;

  &--fetching {
    @include size(100%);
    @include flex(row, center, center);
    .loading-spinner {
      @include size(50px);
      border-width: 0.5rem;
    }
  }

  .back-link {
    margin-top: 8px;
    .is-button__label {
      @include font(17px, 17px, 400);
      svg {
        @include size(30px, 22px);
      }
    }
  }

  &__table-wrapper {
    max-height: 562px;
    overflow-y: hidden;
    margin-top: 24px;
    &__history {
      margin-block: 0 16px;
      color: $black-oak;
    }
    &__payments-info {
      @include flex(row, space-between, center);
      width: 100%;
      padding-inline: 16px;
      .data-display-row {
        width: 100%;
        margin-block: 0 8px;
      }
    }
    &__table {
      overflow-y: auto;
      max-height: 490px;
      width: 100%;
      overflow-x: auto;
      display: block;
      white-space: nowrap;
      border: 2px solid $border-gray;
      border-inline: 1px solid $border-gray;
      thead {
        position: sticky;
        top: 0;
        background-color: $color-white;
        z-index: 1;
        box-shadow: $shadow-md;
        tr {
          border-top: 0;
        }
      }
      tr {
        border: 1px solid $border-gray;
      }
    }

    &__loading {
      &__title {
        @include size(145px, 25px);
        margin-bottom: 20px;
      }
      &__info {
        @include size(100%, 22px);
        margin-bottom: 8px;
        margin-inline: 16px;
      }
    }
  }

  &__empty-payments {
    @include flex(column, center, center);
    @include size(100%);
    &__title {
      position: relative;
      top: 60px;
      margin-bottom: 0;
    }
    &__animation {
      @include size(500px);
    }
  }
  .back-link {
    padding: 0;
  }
}
