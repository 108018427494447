@use '~scss/settings' as *;

.client {
  @include size(100%);
  @include flex(column, flex-start, center);
  padding: 0 1.75rem 1.75rem 1.75rem;
  &__header {
    &__loader {
      @include size(360px, 45px);
    }
  }

  &__body {
    @include flex(column, center, center);
    flex: 1;
    width: 100%;

    .preview-card {
      width: 100%;
      &__content {
        padding-bottom: 1rem;
      }
    }

    &__edit {
      padding-inline: 48px;
    }

    &__loading {
      @include size(50px);
      border-width: 8px;
    }
  }
}
