@use '~scss/settings' as *;

.pagination-bar {
  @include flex(row, flex-start, center);

  gap: 40px;

  &__numbered-list {
    @include flex(row, flex-start, center);
    list-style: none;
    padding: 0px;
    gap: 10px;
  }

  &__three-dots {
    @include flex(row, center, center);
    @include size(30px);
    margin: 0;
  }
}
