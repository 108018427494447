@use '~scss/settings' as *;

.header {
  @include flex(row, space-between, center);

  width: 100%;
  background-color: inherit;
  padding: 1.75rem 10px;
  @include tablet() {
    padding: 1.25rem 1rem 0.5rem;
  }

  &__info {
    @include flex(row, flex-start, center);
    flex-wrap: wrap;

    &__title {
      @include font(30px, 45px, 400);
      text-wrap: nowrap;
      font-family: Alata;
      padding-right: 3rem;

      @include mobile() {
        @include font(24px, 38px, 400);
        padding-right: 2rem;
      }
    }

    &__line {
      border-left: 2px solid black;
      height: 32px;
      padding-right: 1.5rem;
    }

    &__browser {
      @include font(18px, 30px, 400);
      @include mobile {
        @include font(15px, 30px, 400);
      }
    }
  }

  &__navigation {
    @include flex(row, flex-start, center);
    position: relative;
    padding-right: 10px;
  }
}
