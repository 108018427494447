@use '~scss/settings' as *;

.is-catalog {
  @include flex(column);
  @include size(100%);

  padding: 20px 40px;
  &__body {
    @include flex(row, flex-start, flex-start);
    @include size(100%);
    gap: 20px;
  }
  &__preview-card {
    @include size(400px, 800px);
  }

  &__container {
    @include flex(row);
    gap: 30px;
  }

  &__tool-tip {
    width: fit-content;
  }
  &__auto-suggest {
    width: 400px;
  }
}
