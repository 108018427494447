@use '~scss/settings' as *;

.is-language-picker {
  @include flex(row, center, center);
  &--absolute {
    position: absolute;
  }

  &--top-right {
    top: 2.25rem;
    right: 8rem;
    @include tablet() {
      right: 2.5rem;
    }
  }

  &__auth-header {
    margin-right: 14rem;
  }

  &__dashboard-header {
    margin-left: auto;
    margin-right: 2rem;
    display: none;
    @include mobile {
      display: block;
      margin-right: 1.75rem;
    }
  }

  &__trigger {
    @include flex(row, center, center);
    @include size(5rem, 2.25rem);
    @include font(18px, 30px, 400);

    @include mobile() {
      @include size(3.5rem, 1.75rem);
      @include font(15px, 26px, 400);
    }
    transition: height 0.5s, width 0.5s, font-size 0.5s;

    border-radius: 2rem;
    background-color: $color-white;
    border: 1px solid $blue-depths;
    color: $blue-depths;
    cursor: pointer;

    &:hover {
      background-color: $blue-depths;
      color: $color-white;

      transition-property: color;
      transition: 0.3s;
    }
  }

  &__content {
    background-color: $color-white;
    color: $blue-depths;
    border-radius: 0.25rem;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);

    position: relative;
    z-index: 2;

    &__item {
      padding: 0.5rem;
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
      }

      &:last-child {
        border-bottom-left-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &:hover {
        background-color: $blue-depths;
        color: $ghost-white;
      }
    }
  }

  &--tablet {
    @include tablet {
      display: none;
    }
  }

  &--tablet-visible {
    display: none;
    margin-top: 2rem;
    @include tablet() {
      display: block;
    }
    @include mobile() {
      display: none;
    }
  }

  &--sidebar-variant {
    margin-top: auto;
    margin-bottom: 3rem;
    @include mobile {
      display: none;
    }
    .is-language-picker__trigger {
      background-color: transparent;
      color: $color-white;
      border-color: $color-white;

      &:hover {
        background-color: $color-white;
        color: $color-black-29;
      }
    }
  }
}

.sidebar {
  &--closed {
    .is-language-picker {
      &__trigger {
        @include size(3.5rem, 1.75rem);
        @include font(15px, 30px, 400);
      }

      &__content__item {
        @include font(15px, 30px, 400);
      }
    }
  }
}
