@use '~scss/settings' as *;

.toggle-group {
  @include flex(row);
  @include size(fit-content);
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: $color-white;

  &__toggle {
    border-radius: 0;
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}
