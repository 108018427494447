@use '~scss/settings' as *;

.radio-group {
  &--horizontal {
    @include flex(row);
    gap: 32px;
  }
  &--vertical {
    @include flex(column);
  }
  &--error {
    color: $fusion-red;
    .radio-button__checkmark {
      border-color: $fusion-red;
    }
  }
}
