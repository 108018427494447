@use '~scss/settings' as *;

.client-details {
  width: 100%;
  &__upper {
    @include flex(row);
    gap: 32px;
    padding: 16px;
    flex-wrap: wrap;
    &__info-accounts {
      flex: 3;
    }
  }

  &__accounts-card {
    &__modal {
      .modal__container__wrapper {
        width: 720px;
      }
    }
  }

  &__info {
    flex: 1;
    text-wrap: nowrap;
    box-shadow: 0 0 10px -1px rgba(198, 43, 43, 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    padding: 0.25rem 2rem 1.5rem;
    p {
      margin: 10px 0;
    }
  }

  &__total-projects {
    @include font(20px, normal, 32px);
    margin: 2rem 0 0.75rem 0.75rem;
  }

  &__projects-progress {
    @include flex(row);
    gap: 1.25rem;
    flex-wrap: wrap;
  }

  &__line {
    width: 100%;
    height: 0.1rem;
    background-color: $border-gray;
    margin: 1.75rem 0;
  }

  &__chart {
    max-width: 55%;
    max-height: 330px;
    @include laptop() {
      flex-wrap: wrap;
      max-width: none;
    }
    margin-left: 16px;
  }
}
