@use '~scss/settings' as *;

.creditor-info-card {
  &__header {
    @include flex(row, space-between, center);
    &__edit {
      width: fit-content;
      margin-left: auto;
      .is-button__label {
        @include font(20px, 20px, 400);
      }
    }
  }

  &__body {
    @include flex(row, space-between, flex-start);
    width: 100%;
    padding-inline: 16px;
    &__part {
      @include flex(column);
      flex: 1;
      .data-display-row {
        margin-block: 6px;
        &__title {
          min-width: 130px;
          display: inline-block;
        }
      }
    }
  }
}
