@use '~scss/settings' as *;

.search-input {
  position: relative;
  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    fill: $palladium;
    &--search {
      left: 0;
    }
    &--cross {
      @include size(40px);
      right: 0;
      cursor: pointer;
      line {
        stroke: $palladium;
      }
    }
  }
  &__input {
    .is-input {
      &__header,
      &__footer {
        display: none;
      }
      &__input {
        color: $palladium;
        text-indent: 28px;
      }
    }
  }
}
