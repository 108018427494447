@use '~scss/settings' as *;

.personal-information-card {
  &__form {
    &__edit-trigger {
      position: absolute;
      top: 0rem;
      right: 1rem;
      .is-button__label {
        @include font(16px, normal, 400);
        margin-left: 0;
        color: $duke-blue;
      }
      svg {
        @include size(28px);
        fill: $black-oak;
      }
    }
    &__row {
      @include flex(row, space-between);
      @include tablet {
        @include flex(column, flex-start);
      }
      margin-top: 1rem;
      &__field {
        &--disabled {
          opacity: 0.5;
          .is-input__content {
            background-color: $ghost-white;
          }
        }
      }
    }
    &__buttons {
      @include flex(row, flex-start);

      gap: 0.5rem;

      @include above-tablet {
        margin-left: auto;
        margin-right: 2.5rem;
      }
      @include tablet {
        @include flex(row, space-evenly);
      }

      &__button {
        @include size(12rem, 2.75rem);
        .is-button__label {
          @include font(18px, normal, 400);
        }
      }
    }
  }
}
