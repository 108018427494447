@use '~scss/settings' as *;

.auth-wrapper {
  @include flex(column, space-between, center);
  background-color: $ghost-white;
  height: 100%;

  &__header {
    width: 100%;
    min-height: 100px;
    @include flex(row, space-between, center);
    .is-logo-extended {
      @include flex(row, center, center);
      @include size(200px, 100%);
    }
  }
  &__content {
    @include flex(column, center, center);
    @include tablet() {
      width: 400px;
    }
    @include mobile() {
      width: 300px;
    }
    width: 450px;
    margin-top: 50px;

    &__description {
      @include flex(column, flex-start, center);
      width: 100%;
      height: 200px;
      user-select: none;

      .auth-wrapper__illustration {
        background-image: url('../../../public/assets/images/LoginIlustration.png');
        background-size: 50px;
        background-repeat: no-repeat;
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
      }
      p {
        font-family: Alata;
        font-size: 32px;
        margin-top: 15px;
        color: $blue-depths;
      }
    }
  }

  &__footer {
    @include size(100%, 50px);
    @include flex(row, space-between, center);
    @include tablet() {
      @include flex(row, center, center);
      padding-left: 0px;
    }
    padding-left: 20px;
    .is-copyright {
      user-select: none;
      font-size: 13px;
      color: $blue-depths;
      font-family: Alata;
    }
  }
}
