@use '~scss/settings' as *;

.creditor-form {
  &__field {
    width: 30rem;
    margin: 0 0 1rem;
  }
  .is-input {
    &__input {
      @include font(18px, 0, 400);
      background-color: $background-input-gray;
      height: 3rem;
      border: 3px solid $border-gray;
      border-bottom: 0;
      text-indent: 1rem;
    }
    &__content {
      border-color: $border-gray;
    }
    &--error {
      .is-input__content {
        border-color: $fusion-red;
      }
    }
  }

  &__button {
    @include size(8rem, 2.5rem);
    margin: 1.5rem 0 0 auto;
  }
}
