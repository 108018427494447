$color-white: #fff;
$color-primary: #5a358b;
$color-gray-22: #222;
$color-gray-44: #444;
$color-gray-48: #484848;
$color-gray-4F: #4f4f4f;
$color-gray-56: #565656;
$color-gray-61: #616161;
$color-gray-66: #666;
$color-gray-82: #828282;
$color-gray-99: #999;
$color-gray-E4E6: #e4e6e4;
$color-gray-e7: #e7e7e7;
$color-gray-f2f3: #f2f3f7;
$color-gray-f2f3f8: #f2f3f8;
$color-gray-f6f6f6: #f6f6f6;
$color-gray-f9f: #f9fafb;
$color-gray-b2: #b2b2b2;
$color-gray-bd: #bdbdbd;
$color-gray-aa: #aaa;
$color-gray-E0: #e0e0e0;
$color-black: #000000;
$color-gray-005f: #0000005f;
$color-gray-008f: #0000008f;

$color-black-border: #00000021;
$copyright: #00000080;
$modal-box-shadow: #00000040;
$disabled-button: #00000066;

$color-black-border: #00000021;
$box-shadow-profile-menu: rgba(0, 0, 0, 0.09);

//Ncoded-Isplata Colors
$blue-depths: #252d56;
$blue-depths-hover: #4c5aa1;
$blue-depths-darker: #1f2649;
$blue-depths-darker-hover: #191e3a;
$ghost-white: #f6f7ff;
$ghost-white-active: #ecedf7;
$ghost-white-dark: #e6e7ed;
$azure-mist: #f1fff2;
$natures-masterpiece: #006911;
$duke-blue: #0009c2;
$fusion-red: #ff4b4b;
$palladium: #b1b1b1;
$palladium-darker: #929292;
$black-oak: #4f4f4f;
$grapefruit: #ff5a5a;
$glitter: #e7e8ff;
$glitter-hover: #d9daed;
$islamic-green: #008f17;
$purple-illusion: #a9adff;
$secondary-color: #9c9c9c;
$border-gray: #e3e3e3;
$border-gray-active: #b6b6b6;
$background-input-gray: #fafafa;
$warning-yellow-bright: #ffec82b0;
$warning-yellow: #ffb300;

$confirmation: #c0ebc7;
$destructive: #ffeeee;

$color-gray-94: #949494;
$color-black-29: #292929;

$alice-blue: #efefff;
$blue-pigment: #3237a0;
$honeydew: #f1fff2;
$phthalo-blue: #001586;

$payment-error-background: #fce9e9;
