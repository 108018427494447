@use '~scss/settings' as *;

.card-layout {
  @include flex(row);
  @include laptop {
    @include flex(column);
  }

  width: 100%;
  height: fit-content;
  background-color: $color-white;
  word-break: break-word;
  padding: 1rem;

  .is-input {
    margin: 0 2.5rem;
    @include tablet {
      margin: 0 0.25rem;
    }
    .is-input__label,
    .is-input__input {
      @include font(18px, normal, 400);
    }
    .is-input__input {
      text-indent: 6px;
    }
    .is-input__content {
      border-top: 3px solid transparent;
    }
  }

  &__heading {
    width: 30rem;

    min-height: 65px;
    padding: 0 1.5rem;
    @include laptop {
      margin-bottom: 2.5rem;
    }

    &__title {
      @include flex(row, flex-start, center);
      @include font(22px, normal, 400);
      width: max-content;
      @include tablet {
        width: fit-content;
      }
      gap: 0.25rem;
      text-wrap: nowrap;
    }

    &__subtitle {
      @include font(15px, normal, 200);
      color: $black-oak;
    }
  }

  &__content {
    width: 100%;
    padding: 0 1rem;

    &__wrap {
      @include above-tablet {
        min-width: max-content;
      }

      &__sub-card {
        position: relative;
        padding: 1rem;
        margin: 0.5rem;
        min-height: 14rem;
        box-shadow: 0 0px 10px -1px rgb(0 0 0 / 0.1),
          0 4px 8px -2px rgb(0 0 0 / 0.1);

        &__edit-trigger {
          position: absolute;
          top: 0;
          right: 1rem;
          .is-button__label {
            @include font(16px, normal, 400);
            margin-left: 0;
            color: $duke-blue;
          }
          svg {
            @include size(28px);
            fill: $black-oak;
          }
        }
      }
    }
  }
}
