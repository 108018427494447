@use '~scss/settings' as *;

.home {
  @include size(100%);
  @include flex(row, flex-start, flex-start);

  @include mobile() {
    padding-bottom: 4.25rem;
  }
  background-color: $ghost-white;

  .header {
    position: sticky;
    top: 0;
    background: rgba(246, 247, 255, 0.24);
    backdrop-filter: blur(8.5px);
    -webkit-backdrop-filter: blur(8.5px);
    z-index: 2;
  }

  &__main {
    @include size(100%);
    @include flex(column, flex-start, flex-start);

    overflow-y: auto;
    @include tablet() {
      overflow-y: auto;
    }
    overflow-x: hidden;

    padding-left: 5rem;

    @include mobile() {
      padding: 0rem;
    }

    &__header {
      @include size(100%, 120px);

      background-color: white;
    }

    &__body {
      &__loader {
        @include flex(column, flex-start, center);
        @include size(100%);
        gap: 0.5rem;
        &__max-content {
          flex: 1;
          width: 100%;
          position: relative;
        }
        &__min-content {
          @include flex(row);
          gap: 0.5rem;
          flex: 1;
          width: 100%;
          @include tablet {
            flex-direction: column;
          }
        }
      }
    }
  }
}
