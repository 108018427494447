@use '~scss/settings' as *;

.empty-state-layout {
  @include flex(column, center, center);
  flex: 1;

  &__title {
    @include font(30px, 30px, 400);
  }
  &__description {
    @include font(18px, 18px, 400);
    max-width: 700px;
    text-align: center;
    color: $black-oak;
  }
}
