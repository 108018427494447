@use '~scss/settings' as *;

.payment-form {
  @include flex(column);
  width: min(1032px, 90%);
  max-width: 70rem;

  box-shadow: $shadow-xl;

  padding: 2rem 3rem 0 3rem;
  background-color: $color-white;

  &__loading {
    @include size(min(1032px, 90%), 509px);

    padding: 2rem 3rem 0 3rem;

    box-shadow: $shadow-xl;
    background-color: $color-white;

    &__content {
      @include flex(row);
      gap: 2rem;
      &__side {
        @include flex(column);
        width: 460px;
        gap: 22px;

        &__row {
          @include flex(row);

          gap: 1rem;
          &__field {
            flex: 1;

            &__label {
              @include size(70px, 22px);
              margin-block: 4px;
            }
            &__calendar {
              @include size(39px, 46px);
            }
            &__input {
              @include size(100%, 46px);
              &--calendar {
                @include flex(row);
                gap: 0.5rem;
                height: 46px;
                width: 100%;
                &__input {
                  width: 167px;
                }
              }
            }
          }
        }
      }
    }
    &__footer {
      @include flex(row, flex-end);
      width: 100%;
      border-top: 3px solid $border-gray;
      margin-top: 22px;
      padding-block: 12px;
      &__button {
        @include size(128px, 40px);
      }
    }
  }
  &__auto-suggest-select {
    .auto-suggest__trigger {
      height: 66px;
    }
    &__showcase {
      @include flex(column, center, flex-start);
      p {
        @include font(17px, 17px, 400);
        margin: 4px;
      }
    }
  }

  &__creditor {
    position: relative;

    &__change-address {
      position: absolute;
      z-index: 2;

      padding: 0;
      color: $black-oak;

      right: 0;
      top: 0;
      .is-button__label {
        margin: 0;
      }
      svg {
        @include size(20px);
        fill: $black-oak;
      }
    }
  }

  &__content {
    @include flex(row);
    @include below-responsive-md {
      flex-direction: column;
    }
    gap: 2rem;
    &__side {
      flex: 1;
      &__purpose {
        .auto-suggest__trigger {
          height: 46px;
        }
      }
      .process-date-field__content {
        @include tablet() {
          @include flex(column, flex-end, flex-end);
          gap: 0;
          margin-bottom: 1rem;
          .date-picker {
            width: 100%;
          }
          .is-checkbox {
            &__button {
              @include size(22px);
              border-width: 4px;
            }
          }
        }
      }
      &__row {
        flex: 1;
        &__field {
          &--model {
            max-width: 100px;
            @include tablet() {
              max-width: none;
            }
          }
        }
        @include flex(row, space-between, flex-start);
        gap: 1rem;

        @include below-responsive-md {
          flex: 1;
        }
        @include tablet {
          @include flex(column);
          gap: 0;
        }

        &--hidden {
          display: none;
        }
        &__input {
          .is-input__label {
            text-wrap: nowrap;
          }
          &--payment-code {
            .auto-suggest {
              &__title {
                @include font(20px, 30px, 400);
                margin: 0;
              }
              &__trigger {
                height: 46px;
              }
            }
          }
        }
        &__select {
          .select__wrapper__btn {
            width: 80px;
            @include below-responsive-md {
              width: 100%;
            }
          }
        }
      }
      &--right {
        @include flex(column, center, stretch);
        @include tablet {
          align-items: center;
          & > * {
            width: 100%;
          }
        }
      }
    }
  }

  &__date-picker {
    margin-inline: auto;
  }

  &__footer {
    @include flex(row, flex-end);
    width: 100%;
    border-top: 3px solid $border-gray;
    padding-block: 0.75rem;
  }

  &__submit {
    @include size(8rem, 2.5rem);
    border: 0;
    border-radius: 0;
    box-shadow: none;
    .is-button {
      &__label {
        @include font(18px, 18px, 400);
      }
    }
    svg {
      @include size(32px);
      fill: $color-black;
    }
  }
}

.success-modal {
  &__body {
    @include flex(column, center, center);
    &__text {
      @include font(20px, 30px, 400);
      margin: 0;
    }
    &__icon {
      margin-block: 2rem 4rem;
    }
    &__footer {
      &__new-payment-buttons {
        @include flex(row);
        gap: 40px;

        margin-block: 1rem 2rem;

        &__button {
          @include size(210px, 67px);
          flex: 1;
          .is-button__label {
            @include font(18px, 22px, 400);
            color: $duke-blue;
          }
        }
      }
      &__close {
        @include flex(row, center, center);
        width: 100%;
        &__button {
          @include size(210px, 67px);
          @include font(18px, 22px, 400);
        }
      }
    }
  }
  .modal {
    &__container {
      &__wrapper {
        border: 2px solid $border-gray;

        @include size(495px);
        padding: 1rem;
        &__header {
          margin-bottom: auto;
          &__close-btn {
            svg {
              @include size(20px);
            }
            background-color: transparent;
          }
        }
      }
    }
  }
}
