@use '~scss/settings' as *;

.back-link {
  padding: 0;
  .is-button__label {
    @include flex(row, center, center);
    @include font(24px, 24px, 400);
    @include tablet {
      @include font(22px, 22px, 400);
    }
    @include mobile {
      @include font(20px, 20px, 400);
    }
  }

  color: $black-oak;
  &:hover {
    text-decoration: underline;
  }
  svg {
    fill: $black-oak;
    @include tablet {
      @include size(36px);
    }
    @include mobile {
      @include size(32px);
    }
  }
}
