@use '~scss/settings' as *;

.date-picker {
  @include flex(row, flex-start, center);
  .dropdown__content {
    z-index: 4;
    width: fit-content;
    translate: -50% 0 0;
  }

  &__dropdown-content {
    z-index: 5;
  }

  &__trigger {
    @include size(46px);
    margin-top: 8px;
    margin-left: 0.5rem;
    &:hover {
      background-color: $glitter;
    }
    &:disabled {
      opacity: 0.4;
      svg {
        fill: $black-oak;
      }
    }

    &:disabled.date-picker-toggle__trigger:hover {
      opacity: 0.4;
    }
  }
}
