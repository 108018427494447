@use '~scss/settings' as *;

.payments-list {
  @include size(100%);
  &__header {
    @include flex(row, center);
    width: 100%;
  }

  &__body {
    &__empty {
      @include size(100%);
      @include flex(column, center, center);
      &__paragraph {
        @include font(22px, 22px, 400);
        margin-bottom: 0;
      }
      &__animation {
        @include size(450px);
      }
    }
    &--list {
      padding-bottom: 1.5rem;
      &__table {
        background-color: $color-white;
        border-radius: 8px;
        tbody {
          tr {
            td:nth-child(-n + 4) {
              vertical-align: baseline;
            }
            td,
            td:nth-child(4) {
              @include font(16px, 23px, 700);
            }
          }
        }
        tr {
          border: 1px solid $border-gray;
          td,
          th {
            padding-block: 20px;
            width: 130px;
          }
        }
      }
    }
    &--grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: 1fr;
      grid-auto-columns: 1fr;
      gap: 1rem;

      padding-bottom: 1rem;

      &__card {
        height: 100%;
        width: 100%;
        &--error {
          .is-checkbox--checked {
            .is-checkbox__button {
              outline-color: $fusion-red;
              background-color: $fusion-red;
            }
          }
        }
        &--processing {
          .is-checkbox--checked {
            .is-checkbox__button {
              outline-color: $islamic-green;
              background-color: $islamic-green;
            }
          }
        }
      }

      @media (max-width: 1900px) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media (max-width: 1450px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include below-responsive-md {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  &__browsers {
    @include flex(row, flex-start);
    width: 100%;
    gap: 40px;
    &__browser {
      width: 300px;
      .autosuggest-select {
        &__trigger {
          @include flex(row, flex-start, center);
          text-indent: 8px;
          min-height: 48px;
          padding: 0;
        }
      }
    }
    &__button {
      @include size(150px, 48px);
      align-self: center;
      border-color: $black-oak;
      text-wrap: nowrap;
      .is-button__label {
        color: $black-oak;
      }
      box-shadow: none;
    }
    &__add-new-payment {
      @include size(200px, 48px);
      margin-left: auto;
      margin-right: 2rem;
      align-self: center;
    }
  }

  &__footer {
    @include flex(row, center, center);
    &__button {
      width: 200px;
      border: 3px solid $black-oak;
      background-color: $ghost-white;
      margin-block: 2rem;
      .is-button__label {
        @include font(22px, 22px, 400);
        color: $black-oak;
      }
      &:hover {
        .is-button__label {
          color: $black-oak;
        }
      }
    }
  }

  &__footer {
    @include flex(row, center, center);
    &__button {
      width: 200px;
      border: 3px solid $black-oak;
      background-color: $ghost-white;
      margin-block: 2rem;
      .is-button__label {
        @include font(22px, 22px, 400);
        color: $black-oak;
      }
      &:hover {
        .is-button__label {
          color: $black-oak;
        }
      }
    }
  }
}
.payments-grid-loading {
  @include size(100%);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  gap: 1rem;

  @media (max-width: 1900px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include below-responsive-md {
    grid-template-columns: repeat(1, 1fr);
  }
}

.payments-list-loading {
  @include flex(column);
  @include size(100%);
  gap: 1px;
  &__row:nth-child(1) {
    height: 64px;
  }
  &__row {
    height: 70px;
  }
}
