@use '~scss/settings' as *;

.project-form {
  @include flex(column);
  width: 100%;
  &__input {
    width: 100%;
    .is-input {
      &__input {
        @include font(17px, 20px, 400);
        text-indent: 6px;
      }
      &__label {
        @include font(17px, 20px, 400);
      }
      &__footer {
        @include font(14px, 20px, 400);
      }
    }

    &--square {
      .is-input {
        &__content {
          border-top: 3px solid $palladium;
          border-right: 3px solid $palladium;
          border-left: 3px solid $palladium;
          margin-top: 0.75rem;
        }
        &__input {
          background-color: $background-input-gray;
          text-indent: 8px;
        }
      }
    }
  }

  .process-date-field {
    &__label {
      @include font(17px, 20px, 400);
    }
    &__subtitle {
      @include font(15px, 26px, 400);
    }
    &__content {
      margin-top: 0.75rem;
    }
    .is-checkbox__label {
      @include font(17px, 17px, 400);
    }
    .is-input__footer {
      @include font(16px, 20px, 400);
    }
    .calendar {
      width: fit-content;
      &__header {
        span {
          text-wrap: nowrap;
        }
      }
    }
  }

  &__warning {
    color: $fusion-red;
    margin-bottom: 8px;
  }

  &__old-project {
    @include font(16px, 26px, 400);
    color: $black-oak;
    max-width: 600px;
    &__checkbox {
      .is-checkbox {
        &__label {
          color: $black-oak;
          margin-block: 12px;
          margin-left: 4px;
          @include font(16px, 26px, 400);
        }
        &__button {
          outline-color: $palladium;
        }
      }
    }
  }

  .is-button {
    &__label {
      @include font(17px, 20px, 400);
    }
    .loading-spinner {
      @include size(1.25rem);
    }
  }
}
