@use '~scss/settings' as *;

.calendar {
  @include flex(column, space-between);
  border: 2px solid $color-gray-E4E6;
  background-color: $color-white;

  &--large {
    width: 400px;
    .calendar {
      &__header {
        .is-button {
          svg {
            @include size(40px);
          }
        }
      }
      &__footer {
        .is-button {
          height: 48px;
          &__label {
            @include font(20px, 20px, 400);
          }
        }
      }
    }
  }
  &--medium {
    width: 340px;
    .calendar {
      &__header {
        .is-button {
          svg {
            @include size(36px);
          }
        }
      }
      &__footer {
        .is-button {
          height: 40px;
          &__label {
            @include font(17px, 17px, 400);
          }
        }
      }
    }
  }
  &--small {
    width: 300px;
    .calendar {
      &__header {
        .is-button {
          svg {
            @include size(30px);
          }
        }
      }
      &__footer {
        .is-button {
          height: 32px;
          &__label {
            @include font(16px, 16px, 400);
          }
        }
      }
    }
  }

  &__header {
    @include flex(row, space-between);

    span {
      margin-top: 10px;
      font-weight: bold;
    }
    .is-button {
      @include flex(row, center, center);
    }
  }

  &__footer {
    @include flex(row, space-around);

    > * {
      width: 100%;

      &:hover {
        background-color: $glitter;
      }
    }
  }
}
