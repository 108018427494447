@use '~scss/settings' as *;
.calendar--large {
  .calendar-page {
    &__header {
      & > * {
        margin: 10px 0;
        text-align: center;
        font-weight: bold;
        flex-basis: 14.28%;
      }
    }
    &__body {
      > * {
        flex-basis: 14.28%;
        margin: 5px 0;

        .is-button__label {
          font-size: 16px;
        }

        &:hover {
          background-color: $glitter;

          .is-button__label {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.calendar--medium {
  .calendar-page {
    &__header {
      & > * {
        @include font(15px, 15px, 400);
        margin-bottom: 6px;
        text-align: center;
      }
    }
    &__body {
      > * {
        flex-basis: 12.28%;
        margin: 5px 0;

        .is-button__label {
          font-size: 15px;
        }

        &:hover {
          background-color: $glitter;

          .is-button__label {
            font-size: 15px;
          }
        }
      }
    }
  }
}

.calendar--small {
  .calendar-page {
    &__header {
      & > * {
        @include font(14px, 14px, 400);
        margin-bottom: 6px;
        text-align: center;
      }
    }
    &__body {
      > * {
        flex-basis: 10.28%;
        margin: 0;

        .is-button__label {
          font-size: 14px;
        }

        &:hover {
          background-color: $glitter;

          .is-button__label {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.calendar-page {
  &__header {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
  }

  &__button {
    width: 100%;
    &:disabled {
      background-color: transparent;
      .is-button__label {
        color: $border-gray;
      }

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.picked {
  background-color: $glitter;

  .is-button__label {
    color: $duke-blue;
    font-size: 16px;
  }
}
