@use '~scss/settings' as *;

.client-preview-card {
  &__projects {
    &__empty-state {
      @include size(100%);
      @include flex(row, center, center);
      &__button {
        background-color: $blue-depths;
        color: $color-white;
        min-width: 220px;
        padding: 16px 20px;
        line {
          stroke: $color-white;
        }
      }
    }
  }
  &__creditors {
    &__icon {
      @include size(16px);
    }
    &__empty-state {
      @include size(100%);
      @include flex(row, center, center);
      &__button {
        background-color: $blue-depths;
        color: $color-white;
        min-width: 220px;
        padding: 16px 20px;
        line {
          stroke: $color-white;
        }
      }
    }
  }
  &__client-overview {
    &__edit {
      @include flex(column, flex-start);
      padding-inline: 32px;
      gap: 16px;
    }
    &__header {
      &__icon {
        @include size(24px);
      }
    }
    &__loader {
      @include flex(row, center, center);
      @include size(100%);
      &__spinner {
        @include size(40px);
      }
    }
  }
}
