@use '~scss/settings' as *;

.preview-card-with-search {
  @include flex(column);
  height: 860px;
  &__no-data {
    @include flex(row, center, center);
    @include size(100%);
  }
  &__search {
    width: clamp(100px, 90%, 100%);
    position: relative;
    margin-bottom: 12px;

    &--inline {
      margin-inline: 1rem;
      margin-bottom: 0;
    }

    &__lens {
      position: absolute;
      fill: $palladium;
      top: 5px;
      left: 6px;
    }
    &__input {
      .is-input {
        &__header,
        &__footer {
          display: none;
        }
        &__content {
          height: 36px;
          border: 1px solid $border-gray;
          border-radius: 8px;
          background-color: $color-white;
        }
        &__input {
          @include font(17px, 17px, 400);
          text-indent: 32px;
          color: $black-oak;
        }
      }
    }
    &__loader {
      @include size(24px);
      position: absolute;
      right: 8px;
      top: 6px;
    }
  }
  .preview-card {
    &__content {
      padding-inline: 0;
      overflow-y: hidden;
      &__wrap {
        overflow: auto;
      }
    }
    &__heading {
      @include flex(column, center, center);
      &__upper {
        min-height: 40px;
        padding-block: 1rem 0.75rem;
        &__name {
          margin: 0;
          &__title {
            @include font(20px, 20px, 400);
          }
        }
      }
    }
    &__content {
      overflow-y: auto;
      height: 100%;
    }
  }
}
