@use '~scss/settings' as *;

.account-form {
  @include flex(column, flex-start);
  @include size(100%);

  &__row {
    @include flex(row);
    gap: 32px;
  }

  &__footer {
    @include flex(row);
    gap: 24px;
    margin-left: auto;
    margin-top: auto;
    &__button {
      @include size(200px, 44px);
      .is-button {
        &__label {
          @include font(23px, 23px, 400);
        }
      }
    }
  }
}
