@use '~scss/settings' as *;

.custom-link {
  @include flex(row, flex-start, center);

  &--dashboard {
    width: 100%;
    height: 58px;
    padding: 0;
    @include above-mobile {
      padding-left: 1.5rem;
    }
    @include mobile() {
      @include flex(row, center, center);
    }
  }
  &__icon {
    @include flex(row, flex-end, center);

    position: relative;

    &--row-reverse {
      flex-direction: row-reverse;
    }
  }

  &__text {
    flex-basis: 60%;
  }
}

a {
  text-decoration: none;
  color: $color-white;
}

.sidebar {
  .custom-link__text {
    transition: all 0.4s;
    transform-origin: left;
    @include mobile {
      display: none;
    }
  }
}

.sidebar--open {
  .custom-link__text {
    display: block;
    margin-left: 0.5rem;
    opacity: 1;
    transform: scaleX(1);
    @include mobile {
      display: none;
    }
  }
}

.sidebar--closed {
  .custom-link__text {
    transform: scaleX(0);
    opacity: 0;
    @include mobile {
      display: none;
    }
  }
}

.sidebar__navlist__item:hover .custom-link__text {
  color: $black-oak;
}
