@use '~scss/settings' as *;

.project-form-modal {
  &__name {
    color: $duke-blue;
  }

  &__link {
    margin-left: auto;
    svg {
      @include size(26px);
    }
  }
  &__icon {
    margin-right: 16px;
  }

  .modal__container__wrapper {
    padding: 0;
    &__header {
      padding: 1rem 2.25rem 1rem 1.5rem;
      border-bottom: 2px solid $palladium;
      &__title {
        flex: 1;
        margin: 0;
        &__heading {
          margin-left: 0;
          padding: 0;
        }
        &__icon {
          @include flex(row);
          flex: 1;
          margin-top: 0.5rem;
          margin-left: 0.75rem;
        }
      }
    }
    &__body {
      padding: 1rem 2.25rem 1.5rem 1.5rem;
    }
  }

  &__form {
    .project-form {
      &__input {
        width: 100%;
      }
      &__button {
        @include size(7rem, 2.25rem);
        text-transform: none;
        margin-left: auto;
      }
    }
  }
}
