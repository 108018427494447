@use '~scss/settings' as *;

.delete-modal {
  &__description {
    margin: 0;
    color: $palladium;
    &__purpose {
      color: $color-black;
    }
  }
  &__wrapper {
    @include flex(row, center, center);
    @include size(100%, 180px);

    position: relative;
    &__background {
      position: absolute;
    }
    &__icon {
      @include size(60px);
    }
  }
  &__buttons {
    @include flex(row);
    gap: 1.5rem;
    .is-button {
      flex: 1;
      &__label {
        @include font(22px, 22px, 400);
      }
    }
  }
}
