@use '~scss/settings' as *;

.account-preview {
  @include flex(column);
  position: relative;
  width: 100%;
  padding-block: 12px;

  font-family: 'Arimo';

  &__name {
    @include font(16px, 16px, 700);
    @include flex(row, space-between, center);
    color: $blue-depths;
    margin-bottom: 4px;
  }

  &__row {
    @include flex(row, flex-start, center);
    @include font(14px, 14px, 400);
    gap: 8px;
    width: 100%;
    margin-top: 6px;
    color: $palladium;
    svg {
      @include size(14px);
      fill: $palladium;
    }
  }
  &__options {
    @include size(20px);
    padding: 0;

    &__icon {
      fill: $palladium;
    }

    &__button {
      padding: 0;
      padding: 8px 20px;
      text-wrap: nowrap;
      &--send-again {
        @include size(160px, 42px);
      }
    }

    &__modal {
      .modal__container__wrapper {
        max-width: 650px;
      }
      &__buttons {
        @include flex(row, space-between);
        &__button {
          @include size(fit-content, 42px);
          padding-inline: 16px;
          min-width: 120px;

          @include laptop() {
            height: 36px;
            .is-button__label {
              @include font(16px, 16px, 400);
            }
          }

          @include tablet() {
            height: 32px;
            padding-inline: 10px;
            .is-button__label {
              @include font(14px, 14px, 400);
            }
          }
        }
      }
    }
  }
  .dropdown {
    &__content {
      width: fit-content;
    }
  }
}
