@use '~scss/settings' as *;

.header-profile-menu {
  @include flex(row, space-between, center);

  @include mobile() {
    padding: 0;
    gap: 0.25rem;
  }

  width: 100%;
  padding: 10px 20px;
  gap: 20px;
  cursor: pointer;

  &__name {
    @include flex(row, flex-start, center);
    @include font(20px, 30px, 400);

    text-wrap: nowrap;
    gap: 8px;
    margin: 0px;

    &__icon--row-reverse {
      flex-direction: row-reverse;
    }

    &__text {
      @include tablet() {
        display: none;
      }
    }
  }

  &__dropdown-icon {
    > svg {
      transition: transform 0.3s ease-in-out;
    }

    &--open {
      > svg {
        transform: rotateX(180deg);
      }
    }
  }
}
