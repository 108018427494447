@use '~scss/settings' as *;

.logout {
  @include size(100%);
  @include flex(row, center, center);
  background-color: $ghost-white;

  &__card {
    @include flex(column, flex-start, center);
    @include size(fit-content);
    background-color: $color-white;
    padding: 20px 30px;
    box-shadow: $shadow-md;

    &__spinner {
      height: 40px;
    }
  }
}
