@use '~scss/settings' as *;

.auto-suggest {
  position: relative;

  &--disabled {
    opacity: 0.35;
    .auto-suggest__trigger {
      cursor: not-allowed;
    }
    .is-input__input {
      cursor: not-allowed;
    }
  }

  &__title {
    @include font(20px, 20px, 400);
    color: $black-oak;
    text-wrap: nowrap;
    margin-bottom: 3px;
  }

  &__trigger {
    @include flex(row, flex-start, center);
    width: 100%;
    position: relative;
    &--default {
      border-bottom: 3px solid $palladium;
    }
    &--squared {
      border: 3px solid $palladium;
    }
    &--error {
      border-bottom-color: $fusion-red;
    }

    &__showcase {
      @include flex(row, flex-start, center);
      padding-left: 8px;
      height: 100%;
      flex: 1;
      cursor: text;
      &--disabled {
        cursor: not-allowed;
      }
    }

    &__icon {
      @include size(24px);
      padding: 0;
      svg {
        @include size(24px);
      }
      &--arrow {
        @include flex(row, center, center);
        @include size(14px);

        margin-right: 4px;
        transition: transform 0.3s ease-in-out;

        &--row-reverse {
          flex-direction: row-reverse;
        }

        &--open {
          transform: rotateX(180deg);
        }
      }
    }
    &__loading {
      margin-right: 4px;
      .loading-spinner {
        @include size(20px);
      }
    }
  }

  &__error {
    min-height: 22px;
    color: $fusion-red;
  }

  .is-input {
    &__content {
      height: 100%;
    }
    &__input {
      text-indent: 6px;
      color: $secondary-color;
      height: 100%;
    }
  }
  &__input {
    height: 100%;
    &--selected {
      .is-input__input {
        color: $black-oak;
      }
    }
  }

  &__results {
    position: absolute;
    z-index: 4;
    max-height: 12rem;
    width: inherit;

    overflow-y: scroll;
    background-color: white;
    box-shadow: $shadow-md;

    &--empty-state {
      @include flex(row, center, center);
      width: 100%;
      min-height: 50px;
    }

    &__result {
      padding: 0.75rem 1rem;

      cursor: pointer;

      &:focus,
      &:hover {
        background-color: $glitter;
      }
      &--no-data {
        @include flex(row, center, center);
        &:focus,
        &:hover {
          background-color: white;
        }
      }
    }
  }
}
