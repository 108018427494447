@use '~scss/settings' as *;

.paymentcard {
  @include size(100%);

  padding: 0 28px 22px 28px;
  background-color: $color-white;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.13);
  font-family: Arimo;

  &--not-selected {
    opacity: 0.4;
  }
  &--clickable {
    cursor: pointer;
    &:hover.paymentcard--not-selected {
      opacity: 0.7;
    }
  }

  &--error {
    background-color: $payment-error-background;
  }

  &--highlight:not(.paymentcard--verified):not(.paymentcard--error) {
    animation: highlight 2s;
  }
  &--highlight.paymentcard--verified {
    animation: highlight-selected 2s;
  }

  &--highlight.paymentcard--error {
    animation: highlight-error 2s;
  }

  &__modal {
    &--delete {
      &__description {
        margin: 0;
        color: $palladium;
        &__purpose {
          color: $color-black;
        }
      }
      &__wrapper {
        @include flex(row, center, center);
        @include size(100%, 180px);

        position: relative;
        &__background {
          position: absolute;
        }
        &__icon {
          @include size(60px);
        }
      }
      &__buttons {
        @include flex(row);
        gap: 1.5rem;
        .is-button {
          flex: 1;
          &__label {
            @include font(22px, 22px, 400);
          }
        }
      }
    }
  }

  &__header {
    @include flex(row, space-between, center);
    min-height: 45px;

    padding-top: 8px;
    padding-bottom: 4px;

    border-bottom: 1px solid $palladium;

    &__wrap {
      @include flex(row, center, center);
    }

    &__title {
      @include flex(row);
      @include gap(8px);

      svg {
        @include size(20px);
        padding-bottom: 2px;
      }

      span {
        font-weight: bold;
      }
    }

    &__tool-tip {
      max-width: 300px;
      &-icon {
        font-size: 0;
        svg {
          display: block;
        }
      }
    }

    &__controls {
      @include flex(row, center, center);
      .is-button {
        @include size(32px);
        padding: 0;
      }
      &__button {
        svg {
          fill: #00000075;
        }
      }
    }
    &__tooltip {
      max-width: 300px;
    }
    &__icon {
      margin-inline: auto 8px;
      &--checked {
        @include size(24px);
      }
      &--processed {
        @include size(32px);
      }
      &--error {
        @include size(24px);
        fill: $fusion-red;
      }
    }
  }

  &--processed {
    background-color: $azure-mist;

    .paymentcard__info-block__label {
      color: $natures-masterpiece;
    }
  }

  &__body {
    @include flex(row, center, center);
    padding-top: 1rem;

    min-height: 196px;
    min-width: 360px;

    &__left {
      @include flex(column);
      gap: 10px;
      flex: 1;

      padding-right: 1.25rem;
      border-right: 1px solid $palladium;
    }

    &__right {
      @include flex(column, flex-start, flex-start);
      gap: 22px;
      flex: 1;

      padding-left: 1.25rem;

      &__row {
        @include flex(row, space-evenly);
        gap: 1rem;
      }
    }
  }

  &__info-block {
    margin-left: 0;

    &__title {
      font-weight: 900;
      text-wrap: nowrap;
    }

    &__body {
      @include flex(column);
      &__label {
        @include font(15px, 15px, 300);
        color: $black-oak;
        margin: 5px 0;
      }
    }
  }
}

.disabled {
  opacity: 0.7;
}

@keyframes highlight {
  from {
    background-color: $blue-depths;
  }
  to {
    background-color: $color-white;
  }
}

@keyframes highlight-selected {
  from {
    background-color: $blue-depths;
  }
  to {
    background-color: $honeydew;
  }
}
@keyframes highlight-error {
  from {
    background-color: $blue-depths;
  }
  to {
    background-color: $payment-error-background;
  }
}
