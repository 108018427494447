@use '~scss/settings' as *;

.client-overview-edit {
  @include flex(column, flex-start, flex-start);
  @include size(100%);
  flex: 1;
  &__information {
    &__icon {
      @include size(22px);
      margin-right: 0.25rem;
    }
  }

  &__form {
    .is-button {
      @include size(240px, 44px);
      padding: 8px 16px;
    }
    .client-form__footer {
      margin-left: 0;
    }
  }

  .preview-card__content {
    overflow: auto;
  }
  .client-form__body__row {
    flex-direction: row;
  }

  .client-form {
    height: fit-content;
  }
}
