@use '~scss/settings' as *;

.client-form {
  @include flex(column, flex-start, flex-start);
  @include size(100%);

  &__wrapper {
    width: 100%;
  }
  margin-bottom: 0.5rem;

  &__description {
    @include font(22px, 40px, 400);
    width: 570px;
    color: $palladium;
    margin: 0;
  }

  &__input {
    .is-input {
      &__content {
        border: 2px solid $palladium;
      }
      &__input {
        @include font(17px, 20px, 400);
        background-color: $glitter;
        text-indent: 6px;
      }
      &__label {
        @include font(18px, 16px, 400);
      }

      &__footer {
        @include font(14px, 20px, 400);
      }
    }
  }

  &__row {
    @include flex(row);
    width: 100%;
    gap: 2rem;
    @include laptop {
      flex-direction: column;
      gap: 1rem;
    }
  }

  &__footer {
    @include flex(row);
    gap: 24px;
    margin-left: auto;
    margin-top: auto;
    &__button {
      width: 200px;
      .is-button {
        &__label {
          @include font(23px, 23px, 400);
        }
      }
    }
  }

  &--create {
    flex-direction: column;
    gap: 16px;
    .is-input {
      &__content {
        border-top: 0;
        border-left: 0;
        border-right: 0;
      }
      &__input {
        background-color: transparent;
        @include font(20px, 20px, 400);
        @include laptop {
          @include font(18px, 20px, 400);
        }
        @include tablet {
          @include font(16px, 20px, 400);
        }
      }
      &__label {
        @include font(22px, 20px, 400);
        @include laptop {
          @include font(18px, 18px, 400);
        }
        @include tablet {
          @include font(16px, 16px, 400);
        }
      }

      &__footer {
        @include font(18px, 20px, 400);
        @include laptop {
          @include font(15px, 20px, 400);
        }
        @include tablet {
          @include font(14px, 20px, 400);
        }
      }
    }
  }
}
