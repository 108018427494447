@use '~scss/settings' as *;

.payments {
  @include flex(column, flex-start);
  @include size(100%);

  padding-inline: 1.75rem;
  position: relative;
  &__header {
    @include flex(row, space-between);
    padding-bottom: 2rem;
    .header__info {
      flex: 1;
      &__browser {
        flex: 1;
      }
    }
  }

  &__search {
    .is-input {
      &__header {
        display: none;
      }
    }
  }
}
