@use '~scss/settings' as *;

html,
body,
#root {
  @include size(100%);

  scroll-behavior: smooth;

  @supports (-webkit-touch-callout: none) {
    height: calc(100vh - env(safe-area-inset-bottom));
  }
}

* {
  box-sizing: border-box;
}

body {
  @extend %font;

  margin: 0;
  overscroll-behavior: none;
}

.is-select__wrapper__content::-webkit-scrollbar {
  width: 4px;
  scroll-padding-right: 20px;
  background-color: white;
}
.is-select__wrapper__content::-webkit-scrollbar-track {
  background-color: $color-gray-e7;
  @include border-top-radius(4px);
  @include border-bottom-radius(4px);
  margin-block: 4px;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  background-clip: padding-box;
}
.is-select__wrapper__content::-webkit-scrollbar-thumb {
  background-color: $blue-depths;
  @include border-top-radius(6px);
  @include border-bottom-radius(6px);
}
