@use '~scss/settings' as *;

.download-payments {
  &__button {
    @include size(84px, 34px);
    .loading-spinner {
      @include size(20px);
    }
  }
  &__modal {
    .modal__container__wrapper__header__title__heading {
      color: $fusion-red;
      padding: 0;
    }
    &__text {
      color: $black-oak;
      max-width: 500px;
    }
    &__buttons {
      @include flex(row, space-between, center);
      margin-top: 2.5rem;
      .is-button {
        @include size(fit-content);
        @include size(130px, 38px);
        &__label {
          @include font(18px, 18px, 400);
          margin: 0;
        }
      }
    }
  }
}
