@use '~scss/settings' as *;

.is-register-successfully {
  @include flex(column, center, center);
  height: 30rem;
  margin-top: 5rem;

  &__subtitle {
    @include font(28px, 30px, 400);
  }

  &__email {
    @include font(20px, 30px, 400);
    text-align: center;
    margin-top: 3rem;
  }

  &__link {
    @include font(22px, 30px, 400);

    margin-top: auto;
    padding: 0.5rem 2rem;

    text-wrap: nowrap;

    fill: $blue-depths;
    color: $blue-depths;
    background-color: $glitter;

    transition: all 300ms;
    &:hover {
      background-color: $glitter-hover;
    }
  }
}
