@use '~scss/settings' as *;

.projects-preview-card {
  @include flex(column, center, flex-start);
  padding: 16px 30px 20px 30px;
  gap: 6px;
  background-color: $color-white;

  * {
    margin: 0px;
  }

  &--clickable {
    cursor: pointer;
  }

  &--selected {
    background-color: $ghost-white;
  }
  &__info {
    @include flex(row, space-between, center);
    @include font(15px, 23px, 700);
    font-family: 'Arimo';
    width: 100%;
    color: $blue-depths;
  }
  &__link {
    @include size(fit-content);
    @include flex(row, center, center);
    margin-bottom: 4px;
    padding: 2px 12px;
    visibility: hidden;

    &__icon {
      @include size(30px, 24px);
      fill: $color-black-29;
    }
  }
  &:hover {
    .projects-preview-card__link {
      visibility: visible;
    }
  }

  &__percentage {
    @include font(14px, 18px, 400);
    width: 100%;

    &__text {
      @include flex(row, space-between, center);
      font-family: 'Alata';
      margin-bottom: 2px;
      &--proccessed {
        color: $islamic-green;
      }

      &--total {
        color: $blue-depths;
      }
    }

    &__diagram {
      @include flex(row, flex-start, center);
      height: 4px;
      width: 100%;
      background-color: $blue-depths;

      &--proccessed {
        background-color: $islamic-green;
      }
    }
  }

  &:hover {
    background-color: $ghost-white;
  }
}
