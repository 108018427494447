@use '~scss/settings' as *;

.tool-tip {
  @include size(auto);
  cursor: help;

  &--disabled {
    cursor: default;
  }

  &__content {
    position: fixed;
    z-index: 5;

    background-color: $black-oak;
    color: $color-white;

    padding: 2px 8px;
    border-radius: 2px;
    box-shadow: $shadow-lg;
    opacity: 0.85;

    visibility: visible;
    &--hidden {
      visibility: hidden;
      opacity: 0;
    }
  }
}
