@use '~scss/settings' as *;

.paginated-creditors-table {
  @include flex(column);
  @include size(100%);
  flex: 1;

  .preview-card {
    &__content {
      flex: 1;
      &__wrap {
        @include flex(column);
      }
    }
  }
  &__pagination-bar {
    margin: auto 0 0 auto;
  }

  &__loading {
    &__header {
      @include size(100%, 65px);
      margin: 4px 0;
    }
    &__row {
      @include size(100%, 68px);
      margin: 2px 0;
    }
  }

  &__no-data {
    @include size(100%);
    @include flex(row, center, center);
  }
}
