@use '~scss/settings' as *;
.project {
  @include size(100%, fit-content);
  padding: 0;

  &__header {
    @include flex(row, space-between);

    padding-inline: 1.75rem;

    padding-bottom: 16px;
    width: 100%;

    .header__info {
      width: 100%;
      &__browser {
        flex: 1;
      }
    }
    &__title {
      &__loading {
        @include size(130px, 45px);
      }
    }

    &__browser {
      @include flex(row, center, center);
      @include size(100%);

      &__title {
        &--loader {
          @include size(150px, 36px);
        }
      }
      &__loading {
        @include size(100%, 43px);
        flex: 1;
      }
      &__search {
        width: 300px;
        margin-right: 2rem;
        .is-input {
          &__header,
          &__footer {
            display: none;
          }
        }
      }
      .toggle-group {
        margin-inline: 1rem;
      }
      &__buttons {
        @include flex(row);
        gap: 32px;
        margin-inline: auto;
        &--hidden {
          visibility: hidden;
        }
        .is-button {
          &__label {
            text-wrap: nowrap;
          }
        }

        &__notify {
          min-width: 200px;
          border: 1px solid transparent;
        }

        &__toggle {
          @include size(200px, 42px);
          border: 1px solid transparent;
          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
          text-wrap: nowrap;
          &__list {
            box-shadow: $shadow-lg;
          }
          &--on {
            @include flex(row);
            gap: 12px;
            &--processing {
              min-width: 181px;
              padding-inline: 16px;
              .loading-spinner {
                @include size(24px);
              }
            }
          }
        }
      }
      &__modal {
        &--warning__button {
          width: 100%;
        }
        &--error {
          .modal__container__wrapper {
            max-width: 600px;
          }
          .text-area {
            @include size(100%, 200px);
            max-width: 500px;
            max-height: 250px;
            min-height: 100px;
          }
          &__button {
            margin-left: auto;
            min-width: 120px;
          }
        }
        &--notify {
          &__button {
            margin-left: auto;
            min-width: 120px;
          }
        }
      }
    }
  }

  &__body {
    flex: 1;
    padding-inline: 1.75rem;
    &__selecting-table {
      th {
        &:first-child {
          width: 2%;
        }
      }
    }
    &__client {
      @include font(21px, 21px, 400);
      padding-inline: 1.75rem;
      opacity: 0.4;
      margin: 0;
      &--loader {
        @include size(140px, 21px);
        margin-left: 1.75rem;
        margin-bottom: 32px;
      }
    }
    &__controls {
      @include flex(row, flex-start, flex-end);
      @include size(100%, 70px);
      padding-inline: 1.75rem;

      &__checkbox {
        p {
          margin-block: 0 4px;
        }
      }

      &__loading {
        @include size(100%, 30px);
        padding-inline: 1.75rem;
        margin-bottom: 8px;
      }

      .data-display-row {
        margin-block: 0 0.5rem;
      }
    }
    &--list {
      padding-bottom: 1.5rem;
      &__table {
        background-color: $color-white;
        border-radius: 8px;
        tbody {
          tr {
            td:nth-child(-n + 4) {
              vertical-align: baseline;
            }
            td,
            td:nth-child(4) {
              @include font(16px, 23px, 700);
            }
          }
        }
        tr {
          border: 1px solid $border-gray;
          td,
          th {
            padding-block: 20px;
            width: 130px;
          }
        }
      }
    }
    &--empty-state {
      @include flex(column, center, center);
      @include size(100%);
      p {
        @include font(22px, 22px, 400);
        margin: 0;
      }
      &__animation {
        @include size(400px);
      }
    }
  }

  &__footer {
    @include flex(row-reverse, space-between, flex-start);
    box-shadow: 0 -3px 5px 3px rgba(0, 0, 0, 0.25);
    transition: box-shadow 100ms;
    background-color: $ghost-white;
    &--bottom-reached {
      box-shadow: none;
    }
    gap: 8px;
    position: sticky;
    padding: 16px 28px;
    bottom: 0;

    &__button {
      margin-left: auto;
      background-color: $blue-depths;
      color: $color-white;
      svg {
        line {
          stroke: $color-white;
        }
      }
    }
    &__info {
      @include flex(row);
      margin-right: auto;
      flex-wrap: wrap;
      gap: 1rem;
      background-color: $color-white;

      padding: 8px 16px;
      border-radius: 4px;
      border: 2px solid $border-gray;

      .data-display-row {
        &__title {
          color: $color-black-29;
        }
        &__data {
          color: $black-oak;
        }
      }
      &--go-left {
        animation: go-left 2s ease-in;
      }
      &__part {
        @include flex(row);
        gap: 32px;
        flex-wrap: wrap;
        @include laptop() {
          gap: 16px;
        }
      }
    }
  }

  &__browsers {
    @include flex(row, flex-start);
    width: 100%;
    gap: 40px;
    &__browser {
      width: 300px;
      .autosuggest-select {
        &__trigger {
          @include flex(row, flex-start, center);
          text-indent: 8px;
          min-height: 48px;
          padding: 0;
        }
      }
    }

    &__button {
      @include size(150px, 48px);
      align-self: center;
      border-color: $black-oak;
      text-wrap: nowrap;
      .is-button__label {
        color: $black-oak;
      }
      box-shadow: none;
    }
  }

  &__warning-modal {
    .modal__container__wrapper {
      max-width: 600px;
      &__header__title__heading {
        @include font(30px, 30px, 400);
      }
    }
  }
}
