@use '~scss/settings' as *;

.empty-project-card {
  @include flex(column, space-evenly, center);
  min-height: 300px;
  flex: 1;
  gap: 0.75rem;

  position: relative;

  padding: 0.75rem;

  opacity: 0.55;
  box-shadow: $shadow-md;
  border: 4px solid $color-white;
  background-color: $ghost-white;

  &__row {
    @include flex(row);
    flex: 1;
    gap: 1rem;

    width: 100%;

    &__element {
      flex: 1;
      background-color: $color-white;
    }
  }
  &__button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
    border-radius: 4px;
    line {
      stroke: $color-white;
    }
    &:hover {
      background-color: $blue-depths-darker-hover;
    }
    .is-button {
      &__label {
        text-wrap: nowrap;
      }
    }
  }
  &--clickable {
    opacity: 1;
    cursor: pointer;
    box-shadow: $shadow-lg;

    &:hover {
      box-shadow: $shadow-xl;
      .empty-project-card {
        &__button {
          background-color: $blue-depths;
        }
      }
    }
  }

  &--list-view {
    @include size(100%, 64px);
    @include flex(row);
    box-shadow: none;
    min-height: auto;
    gap: 16px;
    flex: auto;
    &.empty-project-card--clickable {
      height: 136px;
    }
  }
}
