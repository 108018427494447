@use '~scss/settings' as *;

.creditor-details-form {
  &__loader {
    &--thin {
      @include size(480px, 30px);
      margin: 18px 0;
    }
    &--thick {
      @include size(480px, 103px);
      margin-bottom: 16px;
    }
    &--button {
      @include size(128px, 40px);
      margin-left: auto;
      margin-top: 25px;
    }
  }
}
