@use '~scss/settings' as *;

.create-payment {
  @include size(100%);
  @include flex(column, center, center);
  @include below-responsive-md {
    justify-content: flex-start;
    padding-top: 4rem;
  }

  position: relative;
  overflow-y: auto;

  &__search {
    .is-input {
      &__header {
        display: none;
      }
    }
  }
  &__back {
    position: absolute;
    top: 1.75rem;
    left: 0;
  }
}
