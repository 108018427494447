@use '~scss/settings' as *;

.address-form {
  &__input {
    margin-block: 1rem;
    width: 300px;
  }
  &__button {
    @include size(100%, 50px);
    margin-top: 1rem;
    &:disabled {
      &:hover {
        background-color: $palladium;
      }
    }
  }
}
