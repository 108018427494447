$global-font-size: 16;
$overlay-window-z: 5;
$menu-z-index: 10;
$modal-z-index: 20;
$notification-z-index: $modal-z-index + 1;
$border-radius: 8px;
$padding-169: 56.25%;
$shadow-md: 0 0 10px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
$shadow-lg: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
$shadow-xl: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
