@use '~scss/settings' as *;

.creditors {
  @include size(100%);
  @include flex(column, flex-start, center);
  min-height: 100%;

  padding-inline: 1.75rem;
  padding-bottom: 1.75rem;

  &--empty-state {
    &__title {
      @include font(22px, 22px, 400);
      margin: 0;
    }
    &__icon {
      line {
        stroke: $color-white;
      }
    }
    &__animation {
      @include size(350px);
    }
    &__button {
      min-width: 220px;
      padding: 16px 20px;
      border-radius: 4px;
      color: $color-white;
      background-color: $blue-depths;
    }
  }

  &__header {
    &__search {
      width: 350px;
    }
  }

  &__body {
    @include size(100%);
    @include flex(column, center, center);
    .preview-card {
      @include flex(column, flex-start, stretch);
      width: 100%;
      &__content {
        height: fit-content;
        flex: 1;
      }
      .skeleton-loader {
        height: 50px;
      }
      .skeleton-loader:nth-child(1) {
        height: 60px;
      }
    }

    &__overview {
      .creditors-table tbody tr td {
        width: 25%;
        padding-block: 8px;
      }
    }
  }
}
