@use '../../scss/settings' as *;

.is-button {
  @include flex(row, center, center);
  cursor: pointer;
  user-select: none;
  width: max-content;
  background-color: transparent;
  border: 0;

  &:disabled {
    background-color: $palladium;
    color: $color-white;
    .is-button__label {
      color: $color-white;
    }
  }

  &--log-out {
    margin-left: 4rem;
    padding: 0 1rem;
    border-radius: 2px;
  }

  &--disabled {
    cursor: not-allowed;
  }
  &--dropdown {
    background-color: #fff;
    color: $black-oak;
    svg {
      fill: $black-oak;
    }
    &:hover {
      background-color: $ghost-white;
      color: $duke-blue;
      svg {
        fill: $duke-blue;
      }
    }
  }

  &--size {
    &-small {
      height: 36px;
      padding-inline: 8px;
      padding-block: 4px;
      border-radius: 2px;
    }
    &-default {
      height: 42px;
      padding-inline: 20px;
      padding-block: 8px;
      border-radius: 4px;
      @include font(20px, 20px, 400);
    }
    &-large {
      height: 48px;
      padding-inline: 16px;
      padding-block: 8px;
      border-radius: 4px;
    }
    &-xl {
      height: 60px;
      padding-inline: 16px;
      padding-block: 8px;
      border-radius: 4px;
    }
    &-icon {
      padding: 8px;
    }
    &-cta {
      min-width: 220px;
      padding: 16px 20px;
      border-radius: 4px;
    }
    &-table-small {
      height: 28px;
      padding-inline: 16px;
      border-radius: 2px;
      box-shadow: none;
      .is-button__label {
        @include font(16px, 16px, 400);
      }
    }
  }

  &--solid-white {
    background-color: $color-white;
    border-radius: 4px;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    &:disabled {
      background-color: $palladium;
      path {
        stroke: $color-white;
      }
    }
    border: 1px solid transparent;
    transition: border-color 0.2s ease-in-out;
    &:not(:disabled) {
      &:hover {
        border: 1px solid $ghost-white-active;
      }
      &:active {
        transform: translateY(1px);
      }
    }
    &:disabled {
      svg {
        fill: $color-white;
      }
    }
  }

  &--destructive {
    border-radius: 4px;
    background-color: $destructive;
    &:active {
      transform: translateY(1px);
    }
  }

  &--confirmation-green {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    &:active {
      transform: translateY(1px);
    }
    background-color: $confirmation;
  }

  &--confirmation-red {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: $color-white;
    &:active {
      transform: translateY(1px);
    }
    background-color: $fusion-red;
  }

  &--confirmation-blue {
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    color: $color-white;
    &:active {
      transform: translateY(1px);
    }
    background-color: $duke-blue;
  }

  &--pagination {
    @include flex(row, center, center);
    @include font(16px, 24px, 700);

    height: 30px;
    min-width: 30px;
    padding-inline: 0;

    cursor: pointer;
    background-color: $ghost-white;
    .is-button__label {
      color: $color-gray-008f;
    }
    &.active {
      .is-button__label {
        color: $phthalo-blue;
      }
    }
  }

  &--icon {
    &:disabled {
      background-color: transparent;
      svg {
        fill: $color-gray-005f;
      }
      .is-button__label {
        color: $color-gray-005f;
      }
    }
  }

  &__label {
    font-family: Alata;
    font-size: 18px;
    margin: 0px;
  }

  &--on-click-translate {
    &:active {
      transform: translate3d(0, 1px, 0);
      transition: transform 50ms;
    }
  }

  &--solid {
    background-color: $blue-depths;
    transition: background-color 0.25s;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    .is-button__label {
      color: white;
    }
    svg {
      fill: white;
    }
    &:not(:disabled) {
      &:hover {
        background-color: $blue-depths-hover;
        .is-button__label {
          color: $color-white;
        }
      }
    }
  }

  &--solid-black {
    transition: 200ms;
    transition-property: color;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);

    background-color: $color-black-29;
    color: $color-white;
    fill: $color-white;

    transition: background-color 200ms;

    &:hover {
      background-color: #424242;
    }
  }
  &--dropdown {
    @include flex(row, flex-start, center);
    @include size(100%, 100%);
    padding: 20px 0 20px 24px;
  }

  &--solid-bright {
    @include size(100%, 48px);
    background-color: $glitter;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);

    transition: background-color 0.2s;

    .is-button__label {
      font-size: 24px;
      color: $blue-depths;
      transition: 0.3s;
      transition-property: color;
    }
    svg {
      fill: $blue-depths;
    }
    &:hover {
      background-color: $glitter-hover;
      &:disabled {
        background-color: $palladium;
        color: $color-white;
      }
    }
  }

  &--solid-gray {
    background-color: $color-gray-f2f3;
    transition: 0.25s;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
    .is-button__label {
      color: black;
    }
    svg {
      fill: white;
    }
    &:hover {
      background-color: $color-gray-e7;
    }
  }

  &--arrow {
    color: $duke-blue;

    svg {
      fill: $duke-blue;
    }
    &:hover {
      color: $blue-depths;

      svg {
        fill: $blue-depths;
      }
    }
  }

  &--link {
    cursor: pointer;
    color: $blue-pigment;

    .is-button__label {
      @include font(15px, 23px, 500);

      margin-bottom: 0px;
    }

    &.is-button--disabled {
      cursor: not-allowed;
    }
  }

  &--transparent {
    background-color: transparent;
    height: 48px;
    svg {
      fill: $blue-depths;
    }
    .is-button__label {
      color: $blue-depths;
      font-size: 24px;
      margin-bottom: 5px;
    }

    transition: 300ms;
    transition-property: color;
    &:hover {
      background-color: $alice-blue;
    }
  }

  &--border {
    @include size(100%, 50px);
    border: 3px solid $duke-blue;
    background-color: transparent;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.418);

    .is-button__label {
      color: $duke-blue;
    }

    svg {
      fill: $duke-blue;
    }

    &:hover {
      border: 3px solid $blue-depths;

      .is-button__label {
        color: $blue-depths;
      }

      svg {
        fill: $blue-depths;
      }
    }
  }

  &--shadow {
    @include flex(row, center, center);
    padding-block: 8px;
    &--icon {
      .is-button__label {
        @include font(16px, 24px, 700);

        font-family: Arimo;
        margin: 0px;
      }
    }

    &--disabled {
      color: $disabled-button;
      cursor: not-allowed;

      svg {
        fill: $disabled-button;
      }
    }
    transition: background-color 0.25s;
    &:hover {
      background-color: $color-gray-f2f3;
    }
  }

  &-icon {
    &--left {
      @include gap(10px, row);
    }
    &--right {
      @include gap(10px, row-reverse);
      flex-direction: row-reverse;
    }

    width: fit-content;
    padding: 3px 12px;
    background-color: $ghost-white;
    cursor: pointer;

    .is-button__label {
      @include font(10px, 15px, 400);
      text-wrap: nowrap;
      color: $blue-depths;
    }

    &--rotate {
      &-left {
        > svg {
          transform: rotate(90deg);
        }
      }
      &-top {
        > svg {
          transform: rotate(180deg);
        }
      }
      &-right {
        > svg {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
