@use '~scss/settings' as *;

.clients {
  @include flex(row);
  @include laptop() {
    flex-direction: column;
    .preview-card-with-tabs {
      min-height: 852px;
      .preview-card__heading {
        .is-button__label {
          display: none;
        }
      }
    }
    .preview-card-with-search {
      height: 400px;
    }
    .sort__label {
      display: none;
    }
    .pagination-bar__change-page-button {
      .is-button__label {
        display: none;
      }
    }
  }

  width: 100%;
  gap: 1rem;
  padding-inline: 1.75rem;

  &__loader {
    &__client-data {
      @include size(100%, 80px);
      margin: 7px 0;
    }
  }

  &__header {
    padding-inline: 1.75rem;
  }

  &__nothing-selected {
    @include size(100%);
    @include flex(column, center, center);
    box-shadow: $shadow-md;
    background-color: $color-white;
    h1 {
      color: $blue-depths;
      margin-bottom: 0;
    }
    &__animation {
      @include size(360px);
    }
    p {
      color: #b9c2cc;
      margin-top: 0;
    }
  }

  &__loader {
    &__client-overview {
      flex: 2;
    }
    &__client-address-book {
      flex: 1;
    }
    &__client-edit {
      flex: 1;
      height: 100%;
    }
  }
}
