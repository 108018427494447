@use '~scss/settings' as *;

.profile {
  @include flex(column);
  @include size(100%);
  gap: 1rem;
  overflow: auto;

  padding-block: 1rem 1.75rem;
  padding-inline: 1.75rem;

  &__card {
    &__button {
      fill: $duke-blue;
      @include gap(0);
    }
  }

  &__personal-information-loader {
    height: 324px;
    @include laptop {
      height: 458.5px;
    }
    @include tablet {
      height: 556.5px;
    }
  }
  &__account-information-loader {
    height: 308px;
    @include laptop {
      height: 442.5px;
    }
    @include tablet {
      height: 638.5px;
    }
  }
  &__security-information-loader {
    height: 272px;
    @include laptop {
      height: 406.5px;
    }
  }
}
