@use '~scss/settings' as *;

.project-info-card {
  @include size(fit-content);
  flex: 1;
  background-color: $color-white;
  padding: 16px 8px 16px 32px;
  position: relative;
  box-shadow: $shadow-md;

  &__data {
    @include font(20px, 20px, 400);
    @include flex(row, flex-start);
  }
  &__text {
    &__main {
      @include font(14px, 14px, 400);
    }
    &__helper {
      @include font(12px, 12px, 400);
      color: $palladium;
      padding-left: 8px;
    }
  }
  &__button {
    position: absolute;
    top: 8px;
    right: 8px;
    .is-button__label {
      @include font(12px, 12px, 400);
    }
  }
}
