@use '~scss/settings' as *;

.register-client {
  &__form {
    @include flex(column, flex-start, center);
    width: 100%;
  }
  &__button {
    width: 270px;
    margin-top: 75px;
  }
}
