@use '~scss/settings' as *;

.process-date-field {
  @include flex(column);
  width: 100%;
  &__label {
    @include font(20px, 30px, 400);
    color: $black-oak;
  }
  &__subtitle {
    @include font(18px, 18px, 400);
    color: $palladium;
    margin: 0 0 8px 0;
  }
  &__content {
    @include flex(row, flex-start, flex-start);
    gap: 1rem;
    .date-picker {
      flex: 1;
      align-items: flex-start;
      .is-input__header {
        display: none;
      }
      .is-button {
        margin-top: 0;
      }
      .calendar {
        &__header {
          span {
            text-wrap: nowrap;
          }
        }
      }
    }
    .is-checkbox {
      gap: 8px;
      justify-content: flex-end;
      flex: 1;
      &__button {
        @include size(40px);
        outline-color: $palladium;
        border-width: 8px;
      }
      &__label {
        @include font(20px, 20px, 400);
        margin: 0;
        color: $black-oak;
      }
    }
  }
}
