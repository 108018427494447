@use '~scss/settings' as *;

.dashboard-body {
  @include size(100%);
  @include flex(column);
  gap: 0.5rem;

  &__subtitle {
    @include font(23px, 23px, 400);
    margin-top: 0;
    color: $black-oak;
  }

  &__icon {
    fill: $black-oak;
  }

  &__preview-card {
    height: 430px;
    overflow: hidden;
    flex: 1;
    @include laptop {
      flex-grow: 1;
      flex: auto;
    }
    &__projects {
      &__empty-state {
        @include flex(column, center, center);
        max-width: 500px;
        &__span {
          text-align: center;
        }
        &__animation {
          @include size(250px);
        }
      }
    }
  }

  &__min-cards {
    @include flex(row);
    @include laptop {
      @include flex(column);
      flex-wrap: nowrap;
    }
    flex: 1;
    gap: 0.5rem;
  }
  &__max-card {
    flex: 1;
    min-width: 100%;

    &__content {
      &--empty {
        @include flex(column, center, center);
        @include size(fit-content, 100%);

        &__item {
          margin: 1rem auto;
          width: fit-content;
          padding: 0 1rem;
        }

        padding-bottom: 1rem;
      }
    }
  }

  &__min-card {
    flex: 1;
  }

  &__loading-card {
    height: 430px;
    flex: 1;
  }
  &__loading-title {
    @include size(461px, 23px);
    margin-bottom: 19px;
  }
}
