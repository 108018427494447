@use '~scss/settings' as *;

.bulk-payment-form {
  &__fields {
    @include flex(row);
    @include size(100%);
    flex: 1;
    gap: 8px;
    .is-input__input {
      height: 54px;
      @include laptop() {
        height: 40px;
      }
    }
    .select__wrapper__btn {
      height: 60px;
      @include laptop() {
        height: 46px;
      }
    }
    &__model {
      max-width: 140px;
      flex: 2;
      .auto-suggest__icon--arrow {
        top: 50px;
        @include laptop() {
          top: 42px;
        }
      }
    }
    &__reference-number {
      flex: 5;
    }
  }
  &__separator {
    width: 100%;
    border-top: 2px solid $palladium;
    margin-block: 8px 16px;
  }

  &__field-help {
    @include flex(row, space-between);
    .tool-tip {
      margin-left: auto;
      &:hover {
        svg {
          fill: $color-black-29;
        }
      }
      svg {
        fill: $palladium;
      }
    }
  }

  &__checkbox {
    align-self: flex-center;
    margin-bottom: 8px;
    .is-checkbox {
      &__button {
        outline-color: $palladium;
      }
      &__label {
        margin: 0 0 0 8px;
        color: $palladium;
        @include font(20px, 20px, 400);
      }
    }
    &--checked {
      .is-checkbox {
        &__button {
          outline-color: $black-oak;
        }
        &__label {
          color: $black-oak;
        }
      }
    }
  }

  .process-date-field {
    .is-checkbox {
      flex: none;
    }
    &__content {
      @include laptop() {
        @include flex(column, flex-start, flex-start);
        gap: 0;
        margin-bottom: 1rem;
        .date-picker {
          width: 100%;
        }
        .is-checkbox {
          &__button {
            @include size(24px);
            border-width: 4px;
          }
          &__label {
            @include font(20px, 20px, 400);
          }
        }
      }
    }
  }

  &__buttons {
    @include flex(row, space-between);
    gap: 32px;
    margin-top: 2rem;
    @include laptop() {
      margin-top: 0;
    }
    &__button {
      @include size(170px, 46px);
      @include laptop() {
        @include size(140px, 40px);
        .is-button__label {
          @include font(16px, 16px, 400);
        }
      }
    }
  }

  &__confirmation-modal {
    .modal__container__wrapper {
      &__header__title {
        margin-inline: auto;
        &__heading {
          padding-bottom: 16px;
        }
      }
      &__subtitle {
        width: fit-content;
        max-width: 370px;
        margin-bottom: 0.5rem;
      }
    }
    .data-display-row {
      margin-block: 10px;
    }
  }
}
