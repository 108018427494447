@use '~scss/settings' as *;

.combobox {
  &--disabled {
    opacity: 0.35;
    .combobox__trigger {
      cursor: not-allowed;
    }
  }
  &__title {
    @include font(20px, 20px, 400);
    color: $black-oak;
    margin-bottom: 3px;
  }
  &__trigger {
    @include size(100%);
    @include flex(row, flex-start, center);
    @include font(17px, 17px, 400);
    min-height: 40px;
    padding: 0 8px;
    border: 2px solid $palladium;
    cursor: pointer;

    transition: border-color 200ms ease;
    &--open {
      border-color: $palladium-darker;
    }
    &--error {
      border-bottom-color: $fusion-red;
    }
    &__placeholder {
      color: $palladium;
    }

    &__icons {
      @include flex(row, flex-start, center);
      margin-left: auto;
      &__cross {
        @include size(24px);
        margin: 0 4px 0 0;
        padding: 0;
      }
      &__vector-arrow {
        transition: transform 300ms ease;
        &--open {
          transform: rotateX(180deg);
        }
        &--closed {
          transform: rotateX(0);
        }
      }
      &__loading {
        @include size(22px);
        margin-right: 8px;
      }
    }
  }
  &__menu {
    border-radius: 4px;
    border: 2px solid $border-gray;
    width: inherit;
    background-color: white;
    box-shadow: $shadow-lg;
    &--error {
      border-color: $fusion-red;
    }
    &__input {
      background-color: $color-white;
      .is-input {
        &__header,
        &__footer {
          display: none;
        }
        &__content {
          border: 0;
          height: 60px;
        }
        &__input {
          height: 100%;
          border-bottom: 2px solid $border-gray;
          &:focus {
            border-bottom: 2px solid $palladium;
          }
        }
        &__input {
          text-indent: 8px;
        }
      }
    }

    &__results {
      overflow-y: auto;
      max-height: 200px;
      padding: 2px;
      &__result {
        padding: 16px 8px;
        cursor: pointer;
        &:hover {
          background-color: $ghost-white;
        }
      }
      &__no-data {
        @include flex(row, center, center);
        min-height: 54px;
      }
    }
  }

  &__footer {
    color: $fusion-red;
    min-height: 22px;
  }

  .dropdown__content {
    width: fit-content;
    padding: 0;
    z-index: 5;
  }
}
