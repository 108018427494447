@use '~scss/settings' as *;

.is-login {
  &__form {
    @include flex(column, center, center);
    width: 100%;

    position: relative;

    &__forgot-password {
      text-wrap: nowrap;
      position: absolute;
      color: $black-oak;
      top: 6.2rem;
      right: 0;
      &:hover {
        color: $color-black-29;
      }
    }

    &__register {
      text-wrap: nowrap;
      color: $duke-blue;
      &:hover {
        text-decoration: underline;
      }
    }

    &__checkbox {
      @include flex(row, space-between, center);
      width: 100%;
      button {
        width: 200px;
      }
    }
    &__inputs {
      width: 100%;
      @include gap(30px, column);
    }

    &__buttons {
      @include flex(row, center, center);
      @include gap(30px, row);
      width: 100%;

      margin-top: 80px;

      &__register {
        width: 40%;
      }
      &__login {
        width: 60%;
        .is-button {
          width: 100%;
        }
      }
    }
  }
}
