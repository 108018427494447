@use '~scss/settings' as *;

.modal {
  @include fixed(top 0 left 0 bottom 0 right 0);

  z-index: 4;

  background-color: rgba($color-black, 0.3);

  &__container {
    &__wrapper {
      @include font(18px, 30px, 400);
      @include flex(column, center);

      position: fixed;
      top: 50%;
      left: calc(50% + 2.5rem);
      @include mobile {
        left: 50%;
      }
      transform: translate(-50%, -50%);
      padding: 2.5rem 3rem;
      @include tablet {
        padding: 1rem 1.5rem;
      }
      box-shadow: 1px 1px 25px 0px $modal-box-shadow;
      background-color: $color-white;

      &__header {
        @include flex(row);
        width: 100%;
        position: relative;
        &__close-btn {
          @include size(1.75rem);
          border: none;
          box-shadow: none;
          padding: 0;
        }
        &__title {
          @include flex(row);
          margin-right: auto;
          &__heading {
            @include font(26px, 38px, 400);
            @include laptop {
              @include font(24px, 35px, 400);
            }
            @include tablet {
              @include font(22px, 32px, 400);
            }
            margin: 0px;
            padding-bottom: 30px;
            margin-right: auto;

            &__icon {
              &--left {
                margin-right: 1rem;
              }
            }
          }
        }
      }

      &__subtitle {
        @include font(20px, 35px, 200);
        @include laptop {
          @include font(18px, 33px, 200);
        }
        @include tablet {
          @include font(16px, 32px, 200);
        }
        width: 80%;
        @include laptop {
          width: 100%;
        }
        color: $secondary-color;
        margin: 0;
        margin-bottom: 1.5rem;
      }
    }
  }
}
