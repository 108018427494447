@use '~scss/settings' as *;

.select {
  &__wrapper {
    @include size(100%, 6rem);
    position: relative;
    z-index: 3;
    font-family: Alata;
    padding: 20px;
    background-color: white;
    color: $blue-depths;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    &--line {
      height: 98px;
      padding: 0;
      box-shadow: none;
      margin: 0;
      .select__wrapper {
        &__label {
          height: 30px;
          @include font(20px, 28px, 400);
        }
        &__content {
          padding-top: 0.5rem;
          &__error {
            height: 22px;
            color: $fusion-red;
          }
        }
        &__btn {
          color: $black-oak;
          background-color: transparent;
          &--line {
            border: 0 0 3px 0 solid $palladium;
          }
        }
      }
    }

    &--squared {
      height: 98px;
      padding: 0;
      box-shadow: none;
      margin: 0;
      .select__wrapper {
        &__label {
          @include font(20px, 28px, 400);
          height: 30px;
          @include mobile {
            @include font(18px, 28px, 400);
          }
        }
        &__content {
          padding-top: 0.5rem;
          &__error {
            height: 22px;
            color: $fusion-red;
          }
        }
        &__btn {
          color: $black-oak;
          background-color: $background-input-gray;
        }
      }
    }
    &--disabled.select__wrapper--squared,
    &--disabled.select__wrapper--line {
      opacity: 0.4;
      .select__wrapper {
        &__btn {
          cursor: not-allowed;
        }
        &__content {
          &__error {
            color: transparent;
          }
        }
      }
    }

    &__label {
      @include font(23px, 35px, 400);

      margin: 0;
      color: $black-oak;
      &--error {
        color: $fusion-red;
      }
    }
    &__btn {
      @include flex(row, space-between, center);
      @include font(25px, 38px, 400);
      max-width: inherit;
      padding-top: 5px;
      padding-bottom: 10px;
      border-bottom: 3px solid #b1b1b1;
      cursor: pointer;
      position: relative;
      &--line {
        @include font(20px, 20px, 400);

        border: 0 0 3px 0 solid $palladium;
        padding: 0;
        padding-left: 0.25rem;
        height: 46px;

        .select__wrapper {
          &__icon {
            position: absolute;
            right: 0.75rem;
            padding: 0;
          }
        }
      }

      &--squared {
        @include font(20px, 20px, 400);

        border: 3px solid $palladium;
        padding: 0;
        padding-left: 0.25rem;
        height: 46px;

        .select__wrapper {
          &__icon {
            position: absolute;
            right: 0.75rem;
            padding: 0;
          }
        }
      }
      &--error {
        border-color: $fusion-red;
        fill: $fusion-red;
      }
    }
    &__content {
      @include font(20px, 30px, 600);

      position: absolute;
      z-index: 2;
      max-height: 15rem;

      overflow-y: scroll;
      background-color: white;
      box-shadow: $shadow-md;

      padding-bottom: 1rem;
      color: $palladium;
      transform-origin: top right;

      &--open {
        animation: onSelectOpen 0.4s ease-in-out forwards;
      }

      &--close {
        animation: onSelectClose 0.4s ease-in-out forwards;
      }

      &--initial {
        height: 0px;
        display: none;
      }

      &__item {
        @include flex(row, space-between, baseline);
        cursor: pointer;

        padding: 0.5rem 1rem;
      }

      &__item:hover {
        background-color: $ghost-white;
        color: $duke-blue;
      }
    }

    &__icon {
      @include flex(row, center, center);

      padding-right: 10px;
      transition: transform 0.3s ease-in-out;

      &--row-reverse {
        flex-direction: row-reverse;
      }

      &--open {
        transform: rotateX(180deg);
      }
    }
  }
}

@keyframes onSelectOpen {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
    transform: scaleY(1);
  }
}
@keyframes onSelectClose {
  0% {
    opacity: 1;
    transform: rotateX(0deg);
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform: rotateX(-90deg);
    transform: scaleY(0);
  }
}
