@use '~scss/settings' as *;

.data-display-row {
  &__title {
    color: $palladium;
  }
  &__data {
    margin-left: 0.5rem;
  }
}
