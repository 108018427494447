@use '~scss/settings' as *;

.accounts-list {
  overflow: hidden;
  flex: 1;
  min-width: 380px;

  height: fit-content;
  min-height: 40%;

  &--loading {
    @include flex(row, center, center);
    flex: 1;
  }

  &__header {
    &__icon {
      @include size(16px);
    }
  }
  &__no-data {
    @include font(20px, 20px, 400);
    @include size(100%);
    @include flex(row, center, center);
    padding-inline: 12px;
    text-align: center;
    flex: 1;
  }

  .preview-card {
    &__heading {
      border-radius: 16px 16px 0 0;
      &__upper {
        min-height: auto;
        &__name {
          &__title {
            @include font(20px, 20px, 400);
          }
        }
      }
    }
    &__content {
      @include flex(column);
      overflow: auto;
      padding: 0;
      flex: 1;
      height: 270px;

      .client-preview {
        background-color: transparent;
      }
      &__wrap {
        @include flex(column);
        padding-inline: 16px;
        flex: 1;
      }
    }
  }
}
