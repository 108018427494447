@use '~scss/settings' as *;

.bulk-payment-form-modal {
  .modal__container__wrapper {
    @include flex(column, flex-start, flex-start);
    @include size(800px, fit-content);
    @include laptop() {
      @include size(fit-content);
      padding-inline: 40px;
    }
    padding-inline: 120px;
    &__subtitle {
      @include font(20px, 30px, 400);
      width: 100%;
      @include font(18px, 28px, 400);
    }

    &__header__title {
      margin-inline: auto;
      &__heading {
        @include font(32px, 49px, 400);
        @include laptop() {
          @include font(28px, 40px, 400);
        }
      }
    }
    &__body {
      width: 100%;
    }

    .is-input__label {
      @include laptop() {
        @include font(18px, 18px, 400);
      }
    }
    .is-checkbox__label {
      @include laptop() {
        @include font(18px, 18px, 400);
      }
    }
    .process-date-field__label {
      @include laptop() {
        @include font(18px, 30px, 400);
      }
    }
  }
}
