@use '~scss/settings' as *;

.text-area {
  @include font(18px, 18px, 400);
  border: 3px solid $border-gray;
  font-family: 'Arimo';
  padding: 4px 8px;
  color: $black-oak;
  &:focus-visible {
    border: 3px solid $border-gray-active;
    outline: 0;
  }
}
