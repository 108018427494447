@use '~scss/settings' as *;

.vector-arrow {
  transition: background-color 400ms, fill 400ms, transform 400ms;
  &--open {
    transform: rotate(90deg);
  }
  &--closed {
    transform: rotate(-90deg);
  }
}

.sidebar {
  @include size(5rem, 100%);
  @include flex(column, flex-start, center);

  @keyframes logoTransform {
    0% {
      visibility: visible;
      position: absolute;
    }
    100% {
      position: absolute;
      visibility: hidden;
      right: -1.55rem;
      top: -0.125rem;
    }
  }

  @keyframes iconTransform {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0.42);
    }
  }

  @keyframes fadeinLogo {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__open-sidebar {
    @include flex(row, center, center);
    @include mobile() {
      display: none;
    }
    padding: 0;
    padding-top: 0.25rem;

    position: absolute;
    width: 3rem;
    height: 3rem;
    border-radius: 999999px;
    background-color: $color-black-29;
    right: -1rem;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: none;
    border: none;

    transition: transform 300ms;
    &:hover {
      background-color: $glitter !important;
      svg {
        fill: $color-black-29;
      }
    }
    svg {
      @include size(1.25rem);
      fill: white;
      color: white;
    }
  }

  transition: width 400ms;

  position: fixed;
  z-index: 3;

  @include mobile() {
    @include size(100%, 3.5rem);
    @include flex(row, flex-start, center);
    bottom: 0;
  }
  .custom-link__icon > svg {
    @include size(1.75rem);
  }

  background-color: $color-black-29;

  &__logo-mini {
    margin: 2rem 2.5rem;
    position: absolute;
    @include mobile() {
      visibility: hidden;
    }
  }

  &--closed {
    .sidebar__logo-max {
      visibility: visible;
      margin: 1.75rem 2.25rem;
      opacity: 0;
      transition: opacity 0.4s;
    }

    .sidebar__logo-mini {
      svg {
        opacity: 1;
        transition: opacity 0.5s;
      }
    }
  }

  &__navlist {
    @include flex(column, flex-start, center);
    @include mobile() {
      @include flex(row, center, center);
      height: 100%;
      row-gap: 0;
    }

    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;

    &__item {
      @include flex(row, flex-start, center);

      height: 100%;
      @include above-mobile {
        width: 100%;
      }
      @include mobile {
        flex: 1;
      }

      svg {
        fill: $color-white;
      }
      &:hover {
        svg {
          fill: $color-black-29;
        }
      }

      &--active {
        background-color: ghostwhite;
        span {
          color: $color-black-29;
        }
        svg {
          fill: $color-black-29;
        }
      }
      &:hover {
        background-color: $ghost-white;

        #onHover {
          fill: $color-black-29;
        }
      }
    }

    @include mobile {
      .custom-link__icon > svg {
        @include size(1.75rem);
      }
    }
  }

  @include above-mobile {
    &--open {
      @include size(12rem, 100%);
      transition: width 400ms;
      box-shadow: 0px 0px 40px 1px rgba(0, 0, 0, 1);
      .sidebar {
        &__navlist__item {
          @include flex(row, flex-start, center);
          @include size(100%, 100%);
        }

        &__logo-mini {
          animation-name: logoTransform;
          animation-duration: 0.4s;
          animation-delay: 0.4s;
          position: absolute;
          right: -1.55rem;
          top: -0.125rem;
          svg {
            animation-name: iconTransform;
            animation-duration: 0.4s;
            transform: scale(0.42);
            transition: transform 1s, opacity 1s;
            opacity: 0;
          }
        }

        &__logo-max {
          margin: 1.75rem 2.25rem;
          visibility: visible;
          opacity: 1;
          transition: transform 1s, opacity 1s;
          @include above-mobile {
            visibility: visible;
          }
        }
      }

      @include above-mobile {
        .custom-link__icon > svg {
          @include size(1.75rem);
        }
      }
    }
    @include tablet {
      .sidebar:hover {
        @include size(108px, 100%);

        .sidebar {
          &__logo-mini {
            visibility: visible;
          }

          &__logo-max {
            opacity: 0;
          }
        }
      }
    }
  }

  @include mobile() {
    &__logo-mini {
      display: none;
    }
    &__logo-max {
      display: none;
    }
  }

  a {
    @include font(18px, 28px, 400);
    font-family: Alata;
  }
}
