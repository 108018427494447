@use '~scss/settings' as *;

.sort {
  @include flex(row, flex-start, center);
  @include font(18px, 18px, 400);
  flex-wrap: wrap;
  gap: 4px;

  &__dropdown {
    margin-left: 8px;
    &__content {
      box-shadow: $shadow-md;
      .is-button {
        padding: 12px 20px;
        &__label {
          @include font(17px, 17px, 400);
        }
      }
    }
  }

  &__select {
    @include flex(row, space-between, center);
    @include font(18px, 18px, 400);
    @include size(fit-content, 36px);
    min-width: 340px;

    cursor: pointer;
    text-indent: 10px;
    background-color: $ghost-white;

    &__icon {
      margin-inline: auto 8px;
      &--open {
        transform: rotateX(-180deg);
      }
      transition: transform 500ms ease;
    }
  }

  &__label {
    text-wrap: nowrap;
  }

  &__order {
    @include size(24px);
    &--asc {
      transform: rotateZ(-90deg);
    }
    &--desc {
      transform: rotateZ(90deg);
    }
    transition: transform 0.2s ease-in-out;
  }
}
